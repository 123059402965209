import { millisecondsToSeconds } from 'date-fns'

const cacheTimeSeconds = 30
const storageKey = 'TOKEN_PRICES'

export const fetchMultipleCoinGeckoPricesByAddress = async (
  tokenAddresses: string[],
  platform = 'arbitrum-one',
): Promise<{ prices: { [tokenAddress: string]: number }; getPrice: (token: string) => number }> => {
  console.log('tokenAddresses',tokenAddresses)
  const cached = localStorage.getItem(storageKey)
  // if (cached) {
  //   const data = JSON.parse(cached)

  //   const timeElapsed = millisecondsToSeconds(Date.now()) - millisecondsToSeconds(data.lastTime)
  //   if (timeElapsed < cacheTimeSeconds) {
  //     // console.log('Returning prices from cache')
  //     // console.log('timeElapsed: ' + timeElapsed)
  //     return {
  //       prices: data.prices,
  //       getPrice: (token: string) => data.prices[token.toLowerCase()] || 0,
  //     }
  //   }
  // }

  //console.log('Token price cache expired. Making API call...')

  const addresses = tokenAddresses.map((t) => t.toLowerCase()).join(',')
  try {
    const response2 = await fetch(`https://api.dexscreener.com/latest/dex/pairs/arbitrum/0x62fddfc2d4b35adec79c6082ca2894eab01ac0db,0x3369839e15d31b884dbd4e8e101fda0125fb08e8,0xD65Ef54B1ff5D9a452B32Ac0c304d1674F761061,0x3f8e688806829f9ba85abbd0c80399230cd80427,0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59,0xc4e56BFe61A5259CBd5Ad1B9C98c75A97C337B7B,0x9c1CCb589C9B9960D03B008da31EA2bb0d1338D9,0x4c42fa9ecc3a17229edf0fd6a8eec3f11d7e00d3`, {
    // const response2 = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${addresses}`, {
    // const response2 = await fetch(`https://api.dexscreener.com/latest/dex/tokens/0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84,0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8,0xaf88d065e77c8cC2239327C5EDb3A432268e5831,0x82aF49447D8a07e3bd95BD0d56f35241523fBab1,0xAD435674417520aeeED6b504bBe654d4f556182F,0x6Aa395F06986ea4eFe0a4630C7865C1eB08D5e7e,0x912CE59144191C1204E64559FE8253a0e49E6548`, {
      method: 'GET',
    })
    const res2 = await response2.json()

    // const response = await fetch(
    //   `https://api.coingecko.com/api/v3/simple/token_price/${platform}?contract_addresses=${addresses}&vs_currencies=usd`,
    // )
    const prices = {}

    const pairs: any[] = res2.pairs || []
    for (const p of pairs) {
      prices[p.baseToken.address.toLowerCase()] = parseFloat(p.priceUsd)
    }

    // for (const address in prices) {
    //   prices[address.toLowerCase()] = prices[address].usd
    // }

    localStorage.setItem(
      storageKey,
      JSON.stringify({
        lastTime: Date.now(),
        prices,
      }),
    )

    //   console.log('Token prices fetched')

    return {
      prices,
      getPrice: (token: string) => {
        return prices[token?.toLowerCase()] || 0
      },
    }
  } catch (error) {
    console.log(`fetchMultipleCoinGeckoPrices: fetch coin gecko prices failed: ${new Date().toUTCString()}`)

    const cached = localStorage.getItem(storageKey)
    if (cached) {
      const data = JSON.parse(cached)
      // console.log(
      //   'fetchMultipleCoinGeckoPrices: Returning cached prices from last time: ' +
      //     `${new Date(data.lastTime).toUTCString()}`,
      // )

      const prices = data.prices
      return {
        prices,
        getPrice: (token: string) => prices[token?.toLowerCase()] || 0,
      }
    }

    //  console.log('fetchMultipleCoinGeckoPrices: nothing to return from cache')

    return {
      prices: {},
      getPrice: (token: string) => 0,
    }
  }
}
