import BigNumber from 'bignumber.js'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import { formatEther, formatUnits } from '@ethersproject/units'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_TWO = new BigNumber(2)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

export const ethersToSerializedBigNumber = (ethersBn: EthersBigNumber): SerializedBigNumber =>
  ethersToBigNumber(ethersBn).toJSON()

export const ethersToBigNumber = (ethersBn: EthersBigNumber): BigNumber => new BigNumber(ethersBn.toString())

export const formatEthersToFloat = (ethersBn: EthersBigNumber) => {
  return parseFloat(formatEther(ethersBn))
}

export const formatEthersToFloatPrecision = (ethersBn: EthersBigNumber, decimals: number) => {
  return parseFloat(formatUnits(ethersBn, decimals))
}
