import Image from 'next/image'
import { ChainId } from '@baseswapfi/sdk-core'
import { isSupportedChain } from 'config/constants/chains'

import base from './ChainSymbols/base.svg'
import scroll from './ChainSymbols/scroll.svg'
import arbitrum from './ChainSymbols/arbitrum.svg'

export function getChainUI(chainId: number) {
  switch (chainId) {
    case ChainId.BASE:
    case ChainId.BASE_GOERLI:
      return {
        Symbol: base,
        dimensions: 28,
      }
    case ChainId.SCROLL_SEPOLIA:
      return {
        Symbol: scroll,
        dimensions: 28,
      }
    case 42161:
      return {
        Symbol: arbitrum,
        dimensions: 28,
      }

    default:
      return undefined
  }
}

type ChainLogoProps = {
  chainId: ChainId
  size?: number
}

export function ChainLogo({ chainId, size }: ChainLogoProps) {
  if (!isSupportedChain(chainId)) return null

  const { Symbol, dimensions } = getChainUI(chainId)
  return (
    <Image
      alt="alt"
      src={Symbol}
      height={size ?? dimensions}
      width={size ?? dimensions}
      style={{ background: '#000', padding: '1px', borderRadius: '50px' }}
    />
  )
}
