import { serializeTokens } from 'utils/serializeTokens'
import { bscTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens(bscTokens)
const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'ARX',
    lpAddresses: {
      97: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
      56: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
      42161: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    v1pid: 0,
    lpSymbol: 'ARX-USDCe LP',
    lpAddresses: {
      97: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
      56: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
      42161: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.usdce,
  },
  {
    pid: 2,
    v1pid: 0,
    lpSymbol: 'ETH-USDCe LP',
    lpAddresses: {
      97: '0x4C42fA9Ecc3A17229EDf0fd6A8eec3F11D7E00D3',
      56: '0x4C42fA9Ecc3A17229EDf0fd6A8eec3F11D7E00D3',
      42161: '0x4C42fA9Ecc3A17229EDf0fd6A8eec3F11D7E00D3',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.usdce,
  },
  {
    pid: 3,
    v1pid: 0,
    lpSymbol: 'USDT-USDCe LP',
    lpAddresses: {
      97: '0xD082d6E0AF69f74F283b90C3CDa9C35615Bce367',
      56: '0xD082d6E0AF69f74F283b90C3CDa9C35615Bce367',
      42161: '0xD082d6E0AF69f74F283b90C3CDa9C35615Bce367',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdce,
  },
  {
  pid: 4,
  v1pid: 0,
  lpSymbol: 'WBTC-USDCe LP',
  lpAddresses: {
    97: '0xA6A6090749B9E3010802C5bFF3845aa6A4AC321B',
    56: '0xA6A6090749B9E3010802C5bFF3845aa6A4AC321B',
    42161: '0xA6A6090749B9E3010802C5bFF3845aa6A4AC321B',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.usdce,
},
{
  pid: 5,
  v1pid: 0,
  lpSymbol: 'UP-USDCe LP',
  lpAddresses: {
    97: '0xFeafF2443f2489366a62FDDc0114Ad53B9aa03E9',
    56: '0xFeafF2443f2489366a62FDDc0114Ad53B9aa03E9',
    42161: '0xFeafF2443f2489366a62FDDc0114Ad53B9aa03E9',
  },
  token: serializedTokens.up,
  quoteToken: serializedTokens.usdce,
},
{
  pid: 6,
  v1pid: 0,
  lpSymbol: 'WBTC-WETH LP',
  lpAddresses: {
    97: '0x10A12127867d3885Ac64b51cc91a67c907eE51db',
    56: '0x10A12127867d3885Ac64b51cc91a67c907eE51db',
    42161: '0x10A12127867d3885Ac64b51cc91a67c907eE51db',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.wbnb,
},
{
  pid: 7,
  v1pid: 0,
  lpSymbol: 'USD+-DAI+ LP',
  lpAddresses: {
    97: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
    56: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
    42161: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
  },
  token: serializedTokens.daip,
  quoteToken: serializedTokens.usdp,
},
{
  pid: 8,
  v1pid: 0,
  lpSymbol: 'USD+-USDCe LP',
  lpAddresses: {
    97: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
    56: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
    42161: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
  },
  token: serializedTokens.usdp,
  quoteToken: serializedTokens.usdce,
},

{ pid: 9,
  v1pid: 0,
  lpSymbol: 'FRAX-USDCe LP',
  lpAddresses: {
    97: '0x9d47610d6Cfc4719D05f4E84344A655fB4FD3f57',
    56: '0x9d47610d6Cfc4719D05f4E84344A655fB4FD3f57',
    42161: '0x9d47610d6Cfc4719D05f4E84344A655fB4FD3f57',
  },
  token: serializedTokens.frax,
  quoteToken: serializedTokens.usdce,
},
{
  pid: 10,
  v1pid: 0,
  lpSymbol: 'sfrxETH-ETH LP',
  lpAddresses: {
    97: '0x00cABe722790e27Ca6154E8FC34281384aa7052C',
    56: '0x00cABe722790e27Ca6154E8FC34281384aa7052C',
    42161: '0x00cABe722790e27Ca6154E8FC34281384aa7052C',
  },
  token: serializedTokens.sfrxeth,
  quoteToken: serializedTokens.wbnb,
}, 

{
  pid: 11,
  v1pid: 0,
  lpSymbol: 'ARB-ETH LP',
  lpAddresses: {
    97: '0x39511b74722afE77d532Eb70632B4B59C559019b',
    56: '0x39511b74722afE77d532Eb70632B4B59C559019b',
    42161: '0x39511b74722afE77d532Eb70632B4B59C559019b',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.wbnb,
},
{
  pid: 12,
  v1pid: 0,
  lpSymbol: 'ARB-USDCe LP',
  lpAddresses: {
    97: '0xD65Ef54B1ff5D9a452B32Ac0c304d1674F761061',
    56: '0xD65Ef54B1ff5D9a452B32Ac0c304d1674F761061',
    42161: '0xD65Ef54B1ff5D9a452B32Ac0c304d1674F761061',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.usdce,
},
{
  pid: 14,
  v1pid: 0,
  lpSymbol: 'CGLD-USDT LP',
  lpAddresses: {
    97: '0xe14E08c43ceBd79f95D181543628C8EDe181bD02',
    56: '0xe14E08c43ceBd79f95D181543628C8EDe181bD02',
    42161: '0xe14E08c43ceBd79f95D181543628C8EDe181bD02',
  },
  token: serializedTokens.cgld,
  quoteToken: serializedTokens.usdt,
},
{
  pid: 15,
  v1pid: 0,
  lpSymbol: 'ARX-ETH LP',
  lpAddresses: {
    97: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
    56: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
    42161: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
  },
  token: serializedTokens.cake,
  quoteToken: serializedTokens.wbnb,
},
{
  pid: 16,
  v1pid: 0,
  lpSymbol: 'DAI-DAI+ LP',
  lpAddresses: {
    97: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
    56: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
    42161: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
  },
  token: serializedTokens.daip,
  quoteToken: serializedTokens.dai,
},
{
  pid: 17,
  v1pid: 0,
  lpSymbol: 'USD+-ETH LP',
  lpAddresses: {
    97: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
    56: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
    42161: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
  },
  token: serializedTokens.usdp,
  quoteToken: serializedTokens.wbnb,
},
{
  pid: 18,
  v1pid: 0,
  lpSymbol: 'WBTC-ETH LP',
  lpAddresses: {
    97: '0x7072abdF4Ade05f48Fa869A62a774bfBA34a10D2',
    56: '0x7072abdF4Ade05f48Fa869A62a774bfBA34a10D2',
    42161: '0x7072abdF4Ade05f48Fa869A62a774bfBA34a10D2',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.wbnb,
},
{
  pid: 18,
  v1pid: 0,
  lpSymbol: 'JRT-ETH LP',
  lpAddresses: {
    97: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
    56: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
    42161: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
  },
  token: serializedTokens.jrt,
  quoteToken: serializedTokens.wbnb,
},
// {
//   pid: 31,
//   v1pid: 0,
//   lpSymbol: 'GNS-WETH LP',
//   lpAddresses: {
//     97: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
//     56: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
//     42161: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
//   },
//   token: serializedTokens.gns,
//   quoteToken: serializedTokens.wbnb,
// },



]

export default priceHelperLps
