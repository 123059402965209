import _define_property from "@swc/helpers/src/_define_property.mjs";
import { scales, variants } from "./types";
import { darkColors } from "../../theme";
var _obj;
export var scaleVariants = (_obj = {}, _define_property(_obj, scales.XS, {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px"
}), _define_property(_obj, scales.SM, {
    height: "32px",
    padding: "12px"
}), _define_property(_obj, scales.MD, {
    height: "36px",
    padding: "0 12px"
}), _define_property(_obj, scales.LG, {
    height: "40px",
    padding: "0 12px"
}), _define_property(_obj, scales.TABLESMALL, {
    height: "32px",
    padding: "0 8px"
}), _define_property(_obj, scales.SWAPTOGGLE, {
    height: "24px",
    padding: "0 8px"
}), _define_property(_obj, scales.MODALTOGGLE, {
    height: "18px",
    padding: "0 0px"
}), _define_property(_obj, scales.TOGGLE, {
    height: "24px",
    padding: "0 4px"
}), _define_property(_obj, scales.PAWG, {
    height: "36px",
    padding: "0px 16px"
}), _obj);
var _obj1;
export var styleVariants = (_obj1 = {}, _define_property(_obj1, variants.PRIMARY, {
    background: "".concat(darkColors.gradients.tealGradLR),
    color: "black",
    fontWeight: "500",
    textTransform: "uppercase",
    borderRadius: "18px",
    border: "2px solid ",
    transition: "all 1s ease-in-out",
    borderColor: "".concat(darkColors.secondary),
    boxShadow: "0 6px 4px #000",
    ":hover": {
        borderColor: "background",
        background: "".concat(darkColors.gradients.tealGradRL),
        border: "2px solid #fff",
        color: "".concat(darkColors.textSubtle),
        transform: "translateY(2px)",
        fontWeight: "700",
        boxShadow: "none"
    },
    ":active": {
        borderColor: "text",
        color: "black",
        fontWeight: "700",
        transform: "translateY(10px)",
        boxShadow: "0 0 12px #fff"
    },
    ":disabled": {
        backgroundColor: "transparent",
        border: "3px solid",
        borderColor: "".concat(darkColors.secondary),
        color: "black"
    }
}), _define_property(_obj1, variants.SECONDARYTRANS, {
    background: "transparent",
    border: "2px solid",
    borderColor: "teal",
    borderRadius: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    color: "text",
    transition: "all 0.5s ease-in-out",
    ":hover": {
        transform: "translateY(2px)",
        background: "".concat(darkColors.gradients.tealGradLR),
        borderColor: "text",
        color: "black"
    },
    ":disabled": {
        background: "transparent",
        border: "2px solid",
        borderColor: "primary",
        color: "".concat(darkColors.textDisabled),
        fontWeight: "500"
    }
}), _define_property(_obj1, variants.ONE, {
    background: "transparent",
    backdropFilter: "blur(3px)",
    borderRadius: "8px",
    fontSize: "1.5rem",
    fontWeight: "500",
    padding: "1rem",
    border: "0px solid white",
    boxShadow: "0 0 4px #fff",
    ":hover": {
        background: "black",
        boxShadow: "2px 0 3px #93BBE4, -2px 0px 3px #FEE383, 0px 2px 3px #5897A0, 0px -2px 3px #F86C0D"
    }
}), _define_property(_obj1, variants.TWO, {
    background: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033);",
    borderRadius: "8px",
    fontSize: "1.2rem",
    fontWeight: "500",
    padding: "1rem",
    border: "0px solid white",
    boxShadow: "0 0 6px #fff",
    ":hover": {
        background: "linear-gradient(to top, #f86808, #088898, #1a4848, #deb033);"
    }
}), _define_property(_obj1, variants.THREE, {
    background: "linear-gradient(-72deg, #dedeff, #ffffff 16%, #dedeff 21%, #ffffff 24%, #dedeff 36%, #ffffff 45%, #ffffff 60%, #dedeff 72%, #ffffff 80%, #dedeff 84%, #555564)",
    borderRadius: "8px",
    fontSize: "1.1rem",
    fontWeight: "600",
    textTransform: "uppercase",
    color: "".concat(darkColors.arbblue),
    letterSpacing: "4px",
    padding: "0rem",
    border: "1px solid white",
    ":hover, :active": {
        background: "transparent",
        opacity: "1.0",
        color: "white"
    }
}), _define_property(_obj1, variants.FOUR, {
    background: "#fff",
    borderRadius: "50px",
    fontSize: "1.1rem",
    fontWeight: "600",
    textTransform: "uppercase",
    color: "white",
    letterSpacing: "4px",
    padding: "1.2rem",
    border: "0px solid white",
    ":hover, :active": {
        background: "transparent",
        opacity: "1.0",
        color: "white"
    }
}), _define_property(_obj1, variants.CONNECT, {
    background: "transparent",
    borderRadius: "50px",
    fontSize: "1.5rem",
    letterSpacing: "1px",
    fontWeight: "500",
    padding: "1rem",
    border: "0px solid white",
    boxShadow: "2px 0 3px #f86808, -2px 0px 3px #088898, 0px 2px 3px #1a4848, 0px -2px 3px #deb033"
}), _define_property(_obj1, variants.CLASSIC, {
    background: "".concat(darkColors.gradients.dexdark),
    borderRadius: "8px",
    fontSize: "1rem",
    fontWeight: "600",
    textTransform: "uppercase",
    color: "".concat(darkColors.text),
    letterSpacing: "4px",
    padding: "4px",
    border: "1px solid white",
    ":hover, :active": {
        // background: "transparent", 
        background: "".concat(darkColors.gradients.dexdark1),
        opacity: "1.0"
    }
}), _define_property(_obj1, variants.FILLGLOW, {
    borderRadius: "8px",
    fontSize: "1rem",
    fontWeight: "600",
    textTransform: "uppercase",
    letterSpacing: "4px",
    padding: "0rem",
    border: "1px solid white",
    ":hover, :active": {
        // background: "transparent", 
        opacity: "1.0",
        textShadow: "none"
    }
}), _define_property(_obj1, variants.QUANTUM, {
    background: "".concat(darkColors.gradients.blue),
    borderRadius: "8px",
    fontSize: "1.0rem",
    fontWeight: "600",
    textTransform: "uppercase",
    color: "text",
    letterSpacing: "4px",
    padding: "0rem",
    border: "1px solid white",
    ":hover, :active": {
        // background: "transparent", 
        background: "".concat(darkColors.gradients.gold),
        opacity: "1.0"
    }
}), _define_property(_obj1, variants.SECONDARY, {
    backgroundColor: "transparent",
    color: "white",
    padding: "0rem",
    borderRadius: "50px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: "0.8rem",
    paddingX: "0.5rem",
    fontWeight: "bold",
    border: "1px solid",
    borderColor: "text",
    boxShadow: "none",
    ":disabled": {
        backgroundColor: "transparent"
    }
}), _define_property(_obj1, variants.ARB, {
    background: "transparent",
    color: "#bbb",
    borderRadius: "8px",
    fontSize: "1.1rem",
    fontWeight: "bold",
    padding: ".5rem",
    border: "2px solid white",
    ":hover, :active": {
        // background: "transparent", 
        background: "".concat(darkColors.gradients.violet)
    }
}), _define_property(_obj1, variants.CALCULATOR, {
    background: "transparent",
    color: "text",
    borderRadius: "6px",
    fontSize: "0.8rem",
    fontWeight: "700",
    padding: ".2rem",
    border: "1px solid white",
    ":hover, :active": {
        // background: "transparent", 
        background: "".concat(darkColors.arblightblue)
    }
}), _define_property(_obj1, variants.PERCENT, {
    background: "transparent",
    borderRadius: "8px",
    paddingX: "1.5rem",
    border: "2px solid #088898",
    color: "#fff",
    ":disabled": {
        backgroundColor: "transparent"
    }
}), _define_property(_obj1, variants.ARBBLUE, {
    background: "transparent",
    borderRadius: "8px",
    fontSize: "0.8rem",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontWeight: "600",
    paddingY: "0px",
    color: "text",
    border: "1px solid white",
    ":disabled": {
        color: "#ccc"
    },
    ":hover": {
        color: "white",
        fontWeight: "bold"
    }
}), _define_property(_obj1, variants.MADRID, {
    background: "transparent",
    borderRadius: "8px",
    fontSize: "0.8rem",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontWeight: "600",
    paddingY: "0px",
    boxShadow: "0 0 12px #fff",
    color: "text",
    border: "2px solid #33f",
    ":disabled": {
        color: "#ccc"
    },
    ":hover": {
        color: "white",
        fontWeight: "bold"
    }
}), _define_property(_obj1, variants.TERTIARY, {
    background: "".concat(darkColors.gradients.blueandgold),
    borderRadius: "8px",
    border: "1px solid black",
    color: "black",
    ":disabled": {
        backgroundColor: "transparent"
    }
}), _define_property(_obj1, variants.SUBTLE, {
    backgroundColor: "textSubtle",
    color: "backgroundAlt"
}), _define_property(_obj1, variants.DANGER, {
    backgroundColor: "failure",
    color: "white"
}), _define_property(_obj1, variants.SUCCESS, {
    backgroundColor: "success",
    color: "white"
}), _define_property(_obj1, variants.TEXT, {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none"
}), _define_property(_obj1, variants.GASANDSLIPPAGE, {
    background: "".concat(darkColors.gradients.blueandgold),
    color: "#000",
    fontWeight: "500",
    fontSize: "14px",
    padding: "8px",
    textTransform: "uppercase",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "#ccc",
    ":hover": {
        background: "".concat(darkColors.primaryBright),
        boxShadow: "0 -4px 12px #60FE00, 0 4px 12px #0098A1",
        transform: "translateY(1px)",
        border: "2px solid",
        borderColor: "".concat(darkColors.primaryBright),
        color: "#fff"
    }
}), _define_property(_obj1, variants.MAX, {
    background: "none",
    padding: "0px",
    color: "".concat(darkColors.orange),
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    ":hover": {
        transform: "translateY(1px)"
    }
}), _define_property(_obj1, variants.GASANDSLIPPAGEOFF, {
    background: "#000",
    color: "#ccc",
    padding: "8px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "uppercase",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "neongreen",
    ":hover": {
        boxShadow: "0 -2px 12px #00FF00, 0 2px 12px #0098A1",
        border: "0px solid #000",
        background: "".concat(darkColors.gradients.blueandgold),
        transform: "translateY(1px)",
        color: "#000"
    }
}), _obj1);
