import { getTokenAddress } from 'config/constants/token-info'
import { getXToken, getBep20Contract } from 'utils/contractHelpers'
import {
  IUserYieldBoosterInfo,
  IXTokenRedeemSettings,
  IYieldBoosterInfo,
  UserXTokenInfo,
  UserXTokenVestingInfo,
  VestingInfo,
  defaultRedeemSettings,
  defaultXTokenUserInfo,
  defaultXTokenUserRedeemInfo,
} from './types'
import multicall, { Call } from 'utils/multicall'
import xTokenAbi from 'config/abi/xARX.json'
import yieldBoostAbi from 'config/abi/YieldBooster.json'
import { formatEther } from '@ethersproject/units'
import { JsonRpcProvider } from '@ethersproject/providers'
import { getMulticallAddress, getYieldBoosterAddress } from 'utils/addressHelpers'
import { Multicaller } from 'utils/v3/multicaller'
import { isSupportedChain } from 'config/constants/chains'
import BigNumber from 'bignumber.js'
import { ethersToBigNumber } from 'utils/bigNumber'

export async function getUserYieldBoosterData(
  account: string,
  chainId: number,
  provider: JsonRpcProvider,
): Promise<IUserYieldBoosterInfo> {
  const xToken = getXToken(chainId)
  const multi = new Multicaller(getMulticallAddress(), provider, xTokenAbi)
  const yieldBoosterAddress = getYieldBoosterAddress()
  multi.call('usageApprovals', xToken.address, 'usageApprovals', [account, yieldBoosterAddress])
  multi.call('usageAllocations', xToken.address, 'usageAllocations', [account, yieldBoosterAddress])

  const data = await multi.execute('getUserYieldBoosterData')

  const userInfo = {
    allocated: formatEther(data.usageAllocations),
    approvalAmount: data.usageApprovals.toString(),
    hasAnyApproval: data.usageApprovals.gt(0),
    positionPoolShare: '0',
  }

  return userInfo
}

// Get high level overall data
export async function getYieldBoosterInfo(chainId: number, provider: JsonRpcProvider): Promise<IYieldBoosterInfo> {
  const yieldBoosterAddress = getYieldBoosterAddress()
  const multi = new Multicaller(getMulticallAddress(), provider, yieldBoostAbi)
  multi.call('totalAllocation', yieldBoosterAddress, 'totalAllocation', [])

  const xToken = getXToken(chainId)
  const xtokenMulti = new Multicaller(getMulticallAddress(), provider, xTokenAbi)
  xtokenMulti.call('usagesDeallocationFee', xToken.address, 'usagesDeallocationFee', [yieldBoosterAddress])

  const [boostData, xtokenData] = await Promise.all([
    multi.execute('getYieldBoosterInfo'),
    xtokenMulti.execute('getYieldBoosterInfo'),
  ])

  // Position to get per pool then also with this
  // yieldBoost.getPoolTotalAllocation(address poolAddress)

  // multi.call(xToken.address, xToken.address, 'usagesDeallocationFee', [ yieldBoosterAddress])
  // const data = await multi.execute('useYieldBoosterInfo')
  const info = {
    totalAllocation: formatEther(boostData.totalAllocation),
    deallocationFee: xtokenData.usagesDeallocationFee.toNumber(),
  }

  return info
}

export const fetchUserXTokenInfo = async (
  account: string,
  chainId: number,
  provider: JsonRpcProvider,
): Promise<{ userInfo: UserXTokenInfo; redeemSettings: IXTokenRedeemSettings }> => {
  if (!isSupportedChain(chainId)) return { userInfo: defaultXTokenUserInfo, redeemSettings: defaultRedeemSettings }

  const xtokenAddress = getTokenAddress('xProtocolToken', chainId)
  const mainTokenAddress = getTokenAddress('ProtocolToken', chainId)

  const multi = new Multicaller(getMulticallAddress(), provider, xTokenAbi)
  multi.call('mainTokenBalance', getTokenAddress('ProtocolToken', chainId), 'balanceOf', [account])
  multi.call('xTokenBalance', xtokenAddress, 'balanceOf', [account])
  multi.call('balances', xtokenAddress, 'getXArxBalance', [account])
  multi.call('xTokenBalance', xtokenAddress, 'balanceOf', [account])
  multi.call('mainTokenApproval', mainTokenAddress, 'allowance', [account, xtokenAddress])
  multi.call('minRedeemRatio', xtokenAddress, 'minRedeemRatio', [])
  multi.call('maxRedeemRatio', xtokenAddress, 'maxRedeemRatio', [])
  multi.call('minRedeemDuration', xtokenAddress, 'minRedeemDuration', [])
  multi.call('maxRedeemDuration', xtokenAddress, 'maxRedeemDuration', [])

  // approval of main token for xtoken to pull it for xtoken conversion
  const [data, 
    // yieldBoosterInfo
  ] = await Promise.all([
    multi.execute('fetchUserXTokenInfo'),
    // getUserYieldBoosterData(account, chainId, provider),
  ])
  console.log('data',data)
  return {
    userInfo: {
      isLoading: false,
      protocolTokenBalance: data.mainTokenBalance.toString(),
      protocolTokenBalanceFormatted: new BigNumber(data.mainTokenBalance.toString()).div(1e18).toString(),
      protocolTokenApprovalAmount: ethersToBigNumber(data.mainTokenApproval).div(1e18).toString(),
      xTokenBalance: data.xTokenBalance.toString(),
      xTokenTotalBalance: data.xTokenBalance.add(data.balances.redeemingAmount).toString(),
      xTokenReedeemingBalance: data.balances.redeemingAmount.toString(),
      // yieldBoosterInfo,
      yieldBoosterInfo: null
    },
    redeemSettings: {
      minRedeemRatio: data.minRedeemRatio.toNumber(),
      maxRedeemRatio: data.maxRedeemRatio.toNumber(),
      minRedeemDuration: data.minRedeemDuration.toNumber(),
      maxRedeemDuration: data.maxRedeemDuration.toNumber(),
    },
  }
}

export const fetchUserXTokenRedeemsInfo = async (
  account: string,
  chainId: number,
  provider: JsonRpcProvider,
): Promise<UserXTokenVestingInfo> => {
  if (!isSupportedChain(chainId)) return defaultXTokenUserRedeemInfo
  const xToken = getXToken(chainId, provider)
  const redeemsLength = await xToken.getUserRedeemsLength(account)

  const redemptionCount = redeemsLength.toNumber()
  let vestingList: VestingInfo[] = []

  if (redemptionCount > 0) {
    const calls: Call[] = []
    let count = 0

    while (count < redemptionCount) {
      calls.push({
        address: xToken.address,
        name: 'getUserRedeem',
        params: [account, count],
      })

      count++
    }

    const vestings: any[] = await multicall(xTokenAbi, calls)

    vestingList = vestings.map((v, idx) => {
      const canFinalize = Date.now() >= v.endTime * 1000

      return {
        arxAmount: formatEther(v.arxAmount),
        xArxAmount: formatEther(v.xArxAmount),
        endTime: new Date(v.endTime * 1000).toUTCString(),
        redeemIndex: idx,
        canFinalize,
      }
    })
  }

  return {
    redemptionCount,
    vestingList,
  }
}
