import Trans from 'components/Trans'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components'
import { Text, flexColumnNoWrap, Button } from '@pancakeswap/uikit'

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
`

const AlertTriangleIcon = styled(AlertTriangle)`
  width: 90px;
  height: 90px;
  stroke-width: 1;
  margin: 36px;
  color: ${({ theme }) => theme.colors.warning};
`

// TODO(cartcrom): move this to a top level modal, rather than inline in the drawer
export default function ConnectionErrorView() {
  const { activationState, tryActivation, cancelActivation } = useActivationState()

  if (activationState.status !== ActivationStatus.ERROR) return null

  const retry = () => tryActivation(activationState.connection, () => console.log('Retrying wallet activation..'))

  return (
    <Wrapper>
      <AlertTriangleIcon />
      <Text marginBottom="8px">
        <Trans>Error connecting</Trans>
      </Text>
      <Text fontSize={16} marginBottom={24} lineHeight="24px" textAlign="center">
        <Trans>
          The connection attempt failed. Please click try again and follow the steps to connect in your wallet.
        </Trans>
      </Text>
      <Button onClick={retry}>
        <Trans>Try again</Trans>
      </Button>
      <Button width="fit-content" padding="0" marginTop={20}>
        <Text onClick={cancelActivation} marginBottom={0}>
          <Trans>Back to wallet selection</Trans>
        </Text>
      </Button>
    </Wrapper>
  )
}
