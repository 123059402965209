import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var baseColors = {
    failure: "#F86C0D",
    primary: "#ccc",
    black: "#000",
    //button color ^^ 
    primaryBright: "#380f54",
    primaryDark: "#FFB237",
    secondary: "#1D2635",
    success: "#fff",
    warning: "#FFB237",
    teal: "#0098A1",
    currentnavcolor: "rgba(10, 15, 20)",
    currentnavcolor2: "rgba(10, 10, 10)",
    currentbgcolor: "rgba(15, 17, 22)"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D",
    grey: "#202020"
};
export var lightColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    secondary: "#fff",
    arbblue: "#1D2635",
    arbgold: "#ccc",
    orange: "#F86C0D",
    arbyellow: "#FEE383",
    arbblack: "#111",
    darkgrey: "#1D2635",
    background: "#191817",
    backgroundDisabled: "#3c3742",
    backgroundAlt: "rgba(0, 0, 0, 0.5)",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    cardBorder: "#D8FAF1",
    contrast: "#FFFFFF",
    dropdown: "#1D2635",
    dropdownDeep: "#100C18",
    silverblue: "#8797a9",
    invertedContrast: "#191326",
    input: "rgba(0, 0, 0, 0.2)",
    inputSecondary: "#262130",
    primaryDark: "#B2B2B2",
    tertiary: "#353547",
    text: "#fff",
    textDisabled: "#666171",
    textSubtle: "#ccc",
    disabled: "#524B63",
    arbdarkblue: "#93BBE4",
    arbgreen: "#5897A0",
    arbblue2: "rgba(18, 18, 114, 1)",
    arbpink: "#6c2a75",
    arbblue3: "#38334d",
    fadedblue: "rgba(147,187,228,0.4)",
    fadedblack: "rgba(10, 10, 10, 0.5)",
    arblightblue: "#fff",
    lightthemegradient: "linear-gradient(90deg, #FEE383, #D8FAF1, #93BBE4, #D8FAF1, #FEE383)",
    gradientBold: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033);",
    gradientCard: "radial-gradient(circle, rgba(147,187,228,0.3), rgba(147,187,228,0.2))",
    gradients: {
        bubblegum: "#242424",
        dexdark: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033);",
        dexlight: "linear-gradient(90deg, #FEE383, #D8FAF1, #93BBE4, #D8FAF1, #FEE383)",
        blueandgold: "linear-gradient(135deg, #1D2635 0%, #FEE383 50%, #1D2635 )",
        darkarb: "linear-gradient(290deg, #1D2635 0%, #FEE383 50%, #1D2635 )",
        inverseBubblegum: "linear-gradient(139.73deg, #FEE383 0%, #1D2635 59%)",
        cardHeader: "0D0C18",
        dexdark1: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033);",
        blue: "linear-gradient(45deg, #fff, #8797a9)",
        violet: "linear-gradient(180deg, rgba(25,24,23,.4) 0%, rgba(147,187,228,.4) 100%)",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FEE383 0%, #FDAB32 100%)",
        charcoalblue: "radial-gradient(circle, #0D0C18, #1C1C1C)",
        quantumcard: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033);",
        metallic: "linear-gradient(to bottom, #f4f4f4, #dcdcdc, #f4f4f4)",
        navgrad: "linear-gradient(to bottom, rgba(10, 10, 10), rgba(20, 20, 27) )",
        navgrad2: "linear-gradient(to right, rgba(10, 10, 10), rgba(20, 20, 27) )",
        navgrad3: "linear-gradient(to bottom, rgba(10, 10, 10), rgba(20, 20, 27), rgba(10, 10, 10) )",
        tealGradLR: "linear-gradient(90deg, #00686e, #008088, #0098a1, #00b0bb)",
        tealGradRL: "linear-gradient(90deg, #00b0bb, #0098a1,#008088,  #00686e)"
    }
});
export var darkColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    secondary: "#fff",
    arbblue: "#1D2635",
    arbgold: "#ccc",
    orange: "#F86C0D",
    arbblack: "#111",
    arbblue2: "#121272",
    arbblue3: "#38334d",
    arbyellow: "#FEE383",
    arblightblue: "#fff",
    arbdarkblue: "#93BBE4",
    arbgreen: "#5897A0",
    arbpink: "#6c2a75",
    silverblue: "#8797a9",
    darkgrey: "#1c1c1c",
    background: "#0D0C18",
    backgroundDisabled: "#3c3742",
    backgroundAlt: "#0D0C18",
    backgroundAlt2: "#0D0C18",
    cardBorder: "#7303c0",
    contrast: "#FFFFFF",
    dropdown: "#000",
    dropdownDeep: "#100C18",
    invertedContrast: "#191326",
    input: "rgba(0, 0, 0, 0.2)",
    inputSecondary: "#262130",
    primaryDark: "#B2B2B2",
    tertiary: "#353547",
    text: "#fff",
    textDisabled: "#666171",
    textSubtle: "#ccc",
    disabled: "#524B63",
    fadedblue: "rgba(147,187,228,0.2)",
    fadedblack: "rgba(10, 10, 10, 0.5)",
    lightthemegradient: "linear-gradient(90deg, #FEE383, #D8FAF1, #93BBE4, #D8FAF1, #FEE383)",
    gradientBold: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033)",
    gradientCard: "radial-gradient(circle, rgba(147,187,228,0.3), rgba(147,187,228,0.2))",
    gradients: {
        bubblegum: "#242424",
        dexdark: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033)",
        dexdark1: "linear-gradient(90deg, #f86808, #088898, #1a4848, #deb033);",
        quantumcard: "linear-gradient(to bottom, #03001e, rgba(115, 3, 192, 0.2))",
        dexlight: "linear-gradient(90deg, #FEE383, #D8FAF1, #93BBE4, #D8FAF1, #FEE383)",
        blueandgold: "radial-gradient(circle, rgba(147,187,228,0.3), rgba(147,187,228,0.2))",
        darkarb: "radial-gradient(circle, rgba(147,187,228,1), rgba(147,187,228,0.2))",
        inverseBubblegum: "radial-gradient(circle, rgba(10, 10, 10, 0.5), rgba(147,187,228,0.2))",
        cardHeader: "#000",
        blue: "linear-gradient(270deg, #fff, #8797a9)",
        metallic: "linear-gradient(to bottom, #f4f4f4, #dcdcdc, #f4f4f4)",
        violet: "radial-gradient(ellipse, rgba(77, 77, 77, 0.5) 0%, rgba(25, 24, 23, 0.5) 100%)",
        violetAlt: "radial-gradient(ellipse, rgba(0, 0, 0, 1), rgba(10, 0, 30, 1)",
        gold: "linear-gradient(90deg, #fff, #8797a9)",
        charcoalblue: "radial-gradient(circle at center, #0D0C18, rgba(0, 0, 0, 0.9))",
        navgrad: "linear-gradient(to bottom, rgba(10, 10, 10), rgba(20, 20, 27) )",
        navgrad2: "linear-gradient(to right, rgba(10, 10, 10), rgba(20, 20, 27) )",
        navgrad3: "linear-gradient(to bottom, rgba(10, 10, 10), rgba(20, 20, 27), rgba(10, 10, 10) )",
        tealGradLR: "linear-gradient(90deg, #00686e, #008088, #0098a1, #00b0bb)",
        tealGradRL: "linear-gradient(90deg, #00b0bb, #0098a1,#008088,  #00686e)"
    }
}); // charcoalblue: "radial-gradient(circle at center, #D8FAF1 10%, #0D0C18 50%, #1C1C1C 90%)", 
