import { ChainId } from '@baseswapfi/sdk-core'

const arbitrumRPC = 'https://arb1.arbitrum.io/rpc'

/**
 * Fallback JSON-RPC endpoints.
 * These are used if the integrator does not provide an endpoint, or if the endpoint does not work.
 *
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const FALLBACK_URLS = {
  [ChainId.ARBITRUM]: [
    'https://arbitrum-mainnet.core.chainstack.com/af6f4d8be2395650d09465a9c423d9b3',
  ],
}

/**
 * Known JSON-RPC endpoints.
 * These are the URLs used by the interface when there is not another available source of chain data.
 */
export const RPC_URLS = {
  [ChainId.ARBITRUM]: [arbitrumRPC, ...FALLBACK_URLS[ChainId.ARBITRUM]],
}
