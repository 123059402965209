import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { ChainId } from '@baseswapfi/sdk-core'
import { RPC_URLS } from 'config/constants/networks'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://arb1.arbitrum.io/rpc'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

const providers: { [chainId in ChainId]?: StaticJsonRpcProvider | null } = {
    [ChainId.ARBITRUM]: null,
  }

const RPC = {
    [ChainId.ARBITRUM]: RPC_URLS[ChainId.ARBITRUM][0],
  }
  
  export function getChainRpcURL(chainId: ChainId) {
    return RPC[chainId]
  }
  
  export function getChainRpcProvider(chainId: ChainId) {
    // Avoid any weird memory leak issues
    if (providers[chainId]) return providers[chainId]
  
    const rpcProvider = new StaticJsonRpcProvider(getChainRpcURL(chainId))
    providers[chainId] = rpcProvider
  
    return providers[chainId]
  }
  
  export const DEFAULT_CHAIN_ID = ChainId.ARBITRUM
  export const defaultRpcProvider = getChainRpcProvider(DEFAULT_CHAIN_ID)

export default null
