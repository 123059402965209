import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-flow: column nowrap;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-flow: row nowrap;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  animation: ",
        " ",
        " ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { css, keyframes } from "styled-components";
export var flexColumnNoWrap = css(_templateObject());
export var flexRowNoWrap = css(_templateObject1());
export var TRANSITION_DURATIONS;
(function(TRANSITION_DURATIONS) {
    TRANSITION_DURATIONS[TRANSITION_DURATIONS["slow"] = 500] = "slow";
    TRANSITION_DURATIONS[TRANSITION_DURATIONS["medium"] = 250] = "medium";
    TRANSITION_DURATIONS[TRANSITION_DURATIONS["fast"] = 125] = "fast";
})(TRANSITION_DURATIONS || (TRANSITION_DURATIONS = {}));
var transitions = {
    duration: {
        slow: "".concat(TRANSITION_DURATIONS.slow, "ms"),
        medium: "".concat(TRANSITION_DURATIONS.medium, "ms"),
        fast: "".concat(TRANSITION_DURATIONS.fast, "ms")
    },
    timing: {
        ease: "ease",
        in: "ease-in",
        out: "ease-out",
        inOut: "ease-in-out"
    }
};
var fadeIn = keyframes(_templateObject2());
export var textFadeIn = css(_templateObject3(), fadeIn, transitions.duration.fast, transitions.timing.in);
