import erc20 from 'config/abi/erc20.json'
import chunk from 'lodash/chunk'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'
import { SerializedFarm } from '../types'
import { SerializedFarmConfig } from '../../config/constants/types'

const stratData = {}

const fetchFarmCalls = (farm: SerializedFarm) => {
  const { lpAddresses, token, quoteToken } = farm
  const lpAddress = getAddress(lpAddresses)
  return [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [getMasterChefAddress()],
    },
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address,
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: 'decimals',
    },
  ]
}

export const fetchPublicFarmsData = async (farms: SerializedFarmConfig[]): Promise<any[]> => {
  const farmCalls = farms.flatMap((farm) => fetchFarmCalls(farm))
  const chunkSize = farmCalls.length / farms.length
  try {
    const farmMultiCallResult = await multicallv2(erc20, farmCalls)
    return chunk(farmMultiCallResult, chunkSize)
  } catch (error) {
    console.log('ERRORRRRRRRRRR', error)
  }
}

export const getAllQuantumData = async (farms: SerializedFarmConfig[]) => {
  const addresses = farms
    .filter((f) => f.quantum)
    .map((f) => f.lpAddresses[42161])
    .join(',')
  try {
    if (stratData[addresses]) return stratData[addresses]

    const res: any = await fetch(`https://api.defiedge.io/arbitrum/details?strategies=${addresses}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const data = await res.json()
    stratData[addresses] = data

    return data
  } catch (error) {
    console.log('Defiedge http error. Trying cached version')
    if (stratData[addresses]) return stratData[addresses]
  }
}
