import { BsDropletFill } from "@react-icons/all-files/bs/BsDropletFill";
import { AiOutlineSwap } from "@react-icons/all-files/ai/AiOutlineSwap";
import { BsUnlockFill } from "@react-icons/all-files/bs/BsUnlockFill";
import { GiTwoCoins } from "@react-icons/all-files/gi/GiTwoCoins";
import { BsImages } from "@react-icons/all-files/bs/BsImages";
import { GiFarmTractor } from "@react-icons/all-files/gi/GiFarmTractor";
import { GiTowerBridge } from "@react-icons/all-files/gi/GiTowerBridge";
export var routes = [
    {
        title: "TRADE",
        label: "TRADE",
        href: "/swap",
        Icon: AiOutlineSwap,
        icon: AiOutlineSwap,
        sub: false
    },
    // need to add in logic for confirmModalId
    {
        title: "Perpetual",
        label: "Perpetual",
        href: "https://perpetual.arbidex.fi/",
        confirmModalId: "usCitizenConfirmModal",
        Icon: BsImages,
        icon: BsImages,
        sub: false
    },
    {
        title: "LIQUIDITY",
        label: "LIQUIDITY",
        href: "/liquidity",
        Icon: BsDropletFill,
        icon: BsDropletFill,
        sub: false
    },
    {
        title: "FARMS",
        label: "FARMS",
        href: "/farms",
        Icon: GiFarmTractor,
        icon: GiFarmTractor,
        sub: false
    },
    {
        title: "STAKING",
        label: "STAKING",
        href: "/pools",
        Icon: GiTwoCoins,
        icon: GiTwoCoins,
        sub: false
    },
    {
        title: "xARX",
        label: "xARX",
        href: "/XARX",
        Icon: BsUnlockFill,
        icon: BsUnlockFill,
        sub: false
    },
    // {
    //   title: "YIELD BOOST",
    //   label: "YIELD BOOST",
    //   href: '/xmswap/yield-booster',
    //   icon: BiLinkAlt,
    //   Icon: BiLinkAlt,
    //   sub: false,
    // },
    // {
    //   title: "DeepShot",
    //   label: "Deepshot",
    //   href: 'https://deepshot.xyz',
    //   icon: GiCrossedBones,
    //   Icon: GiCrossedBones,
    //   sub: false,
    // },
    {
        title: "BRIDGE",
        label: "BRIDGE",
        href: "/bridge",
        Icon: GiTowerBridge,
        icon: GiTowerBridge,
        sub: true
    }, 
];
