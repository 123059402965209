import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID, defaultRpcProvider } from 'utils/providers'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  const { provider, chainId, ...web3React } = useWeb3React()

  return { library: provider ?? defaultRpcProvider, chainId: chainId ?? DEFAULT_CHAIN_ID, ...web3React }
}

export default useActiveWeb3React
