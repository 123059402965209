import BigNumber from 'bignumber.js'
import poolsConfig, { livePools } from 'config/constants/pools'
import smartChefABI from 'config/abi/StakingPool.json'
import erc20ABI from 'config/abi/erc20.json'
import v3PoolAbi from '../../config/abi/UniswapV3Pool.json'
import multicall, { multicallv2 } from 'utils/multicall'
import { getAddress, getMulticallAddress } from 'utils/addressHelpers'
import { BIG_ZERO, formatEthersToFloatPrecision } from 'utils/bigNumber'
import chunk from 'lodash/chunk'
import sousChefV3 from '../../config/abi/sousChefV3.json'
import { Multicaller } from 'utils/v3/multicaller'
import { getMulticallContract } from 'utils/contractHelpers'
import { Token, V3_CORE_FACTORY_ADDRESSES } from '@baseswapfi/sdk-core'
import { getTokenInstance, getTokenInstanceNoToken } from 'config/constants/token-info'
import { ERC20_ABI } from 'config/abi/erc20'
import { FeeAmount, computePoolAddress } from '@baseswapfi/v3-sdk2'
import { defaultRpcProvider } from 'utils/providers'
import { isSupportedChain } from 'config/constants/chains'
import { useV3FactoryContract } from 'hooks/useContract'
import { useSingleContractMultipleData } from 'state/multicall/hooks'

const startEndBlockCalls = livePools.flatMap((poolConfig) => {
  return [
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'startTime',
    },
    {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndTime',
    },
  ]
})

const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0 && p.sousId !== 69)

export const fetchPoolsBlockLimits = async () => {
  // const startEndBlockRaw = await multicall(sousChefABI, startEndBlockCalls)

  // const startEndBlockResult = startEndBlockRaw.reduce((resultArray, item, index) => {
  //   const chunkIndex = Math.floor(index / 2)

  //   if (!resultArray[chunkIndex]) {
  //     // eslint-disable-next-line no-param-reassign
  //     resultArray[chunkIndex] = [] // start a new chunk
  //   }

  //   resultArray[chunkIndex].push(item)

  //   return resultArray
  // }, [])

  return poolsWithEnd.map((cakePoolConfig, index) => {
    // const [[startBlock], [endBlock]] = startEndBlockResult[index]
    return {
      sousId: cakePoolConfig.sousId,
      // startBlock: startBlock.toNumber(),
      startBlock: 1679168985,
      // endBlock: endBlock.toNumber(),
      endBlock: 1695066584,
    }
  })
}

const poolsBalanceOf = poolsConfig.map((poolConfig) => {
  return {
    address: poolConfig.stakingToken.address,
    name: 'balanceOf',
    params: [getAddress(poolConfig.contractAddress)],
  }
})

export const fetchPoolsTotalStaking = async () => {
  const poolsTotalStaked = await multicall(erc20ABI, poolsBalanceOf)

  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
  }))
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
): Promise<{ [key: string]: { stakingLimit: BigNumber } }> => {
  const validPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'ETH' && !p.isFinished)
  // .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

  // Get the staking limit for each valid pool
  const poolStakingCalls = validPools
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress)
      return ['hasUserLimit', 'poolLimitPerUser'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolStakingResultRaw = await multicallv2(smartChefABI, poolStakingCalls, { requireSuccess: false })
  const chunkSize = poolStakingCalls.length / validPools.length
  const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
  return poolStakingChunkedResultRaw.reduce((accum, stakingLimitRaw, index) => {
    const hasUserLimit = stakingLimitRaw[0]
    const stakingLimit = hasUserLimit && stakingLimitRaw[1] ? new BigNumber(stakingLimitRaw[1].toString()) : BIG_ZERO
    return {
      ...accum,
      [validPools[index].sousId]: { stakingLimit },
    }
  }, {})
}

// const poolsWithV3 = poolsConfig.filter((pool) => pool?.version === 3)
const poolsWithV3 = []

export const fetchPoolsProfileRequirement = async (): Promise<{
  [key: string]: {
    required: boolean
    thresholdPoints: BigNumber
  }
}> => {
  const poolProfileRequireCalls = poolsWithV3
    .map((validPool) => {
      const contractAddress = getAddress(validPool.contractAddress)
      return ['pancakeProfileIsRequested', 'pancakeProfileThresholdPoints'].map((method) => ({
        address: contractAddress,
        name: method,
      }))
    })
    .flat()

  const poolProfileRequireResultRaw = await multicallv2(sousChefV3, poolProfileRequireCalls, { requireSuccess: false })
  const chunkSize = poolProfileRequireCalls.length / poolsWithV3.length
  const poolStakingChunkedResultRaw = chunk(poolProfileRequireResultRaw.flat(), chunkSize)
  return poolStakingChunkedResultRaw.reduce((accum, poolProfileRequireRaw, index) => {
    const hasProfileRequired = poolProfileRequireRaw[0]
    const profileThresholdPoints = poolProfileRequireRaw[1]
      ? new BigNumber(poolProfileRequireRaw[1].toString())
      : BIG_ZERO
    return {
      ...accum,
      [poolsWithV3[index].sousId]: {
        required: hasProfileRequired,
        thresholdPoints: profileThresholdPoints.toJSON(),
      },
    }
  }, {})
}

export const getPoolsTVLData = async (
  pools: string[],
  getPrice: (token: string) => number,
  provider,
): Promise<{ pool: string; token0: Token; token1: Token; tvl: number }[]> => {
  const tokenCaller = new Multicaller(getMulticallContract().address, provider, v3PoolAbi)

  pools.forEach((address) => {
    tokenCaller.call(`${address}-token0`, address, 'token0')
    tokenCaller.call(`${address}-token1`, address, 'token1')
  })

  const tokenInfo = await tokenCaller.execute('getPoolsTVLData')

  const mapped = pools.map((p, i) => {
    return {
      pool: p,
      token0: getTokenInstance(tokenInfo[`${p}-token0`]),
      token1: getTokenInstance(tokenInfo[`${p}-token1`]),
    }
  })

  const balanceCaller = new Multicaller(getMulticallContract().address, provider, ERC20_ABI)
  mapped.forEach((p) => {
    balanceCaller.call(`${p.pool}-${p.token0.address}`, p.token0.address, 'balanceOf', [p.pool])
    balanceCaller.call(`${p.pool}-${p.token1.address}`, p.token1.address, 'balanceOf', [p.pool])
  })

  const balanceInfo = await balanceCaller.execute('getPoolsTVLData')

  const mappedWithBalances = mapped.map((p, i) => {
    const bal0 = formatEthersToFloatPrecision(balanceInfo[`${p.pool}-${p.token0.address}`], p.token0.decimals)
    const bal1 = formatEthersToFloatPrecision(balanceInfo[`${p.pool}-${p.token1.address}`], p.token1.decimals)
    const price0 = getPrice(p.token0.address)
    const price1 = getPrice(p.token1.address)
    const tvl = parseFloat((bal0 * price0 + bal1 * price1).toFixed(6))

    return {
      ...p,
      tvl,
    }
  })

  return mappedWithBalances
}

export async function getPoolSlot0Infos(
  positions: { token0: string; token1: string; fee: FeeAmount }[],
  chainId: number,
  uniswapV3FactoryContract: any
) {
  if (!isSupportedChain(chainId)) return []

  const multi = new Multicaller(getMulticallAddress(), defaultRpcProvider, v3PoolAbi)
  // positions.forEach(async (pos, idx) => {

  //   const TokA = getTokenInstance(pos.token0)
  //   const TokB = getTokenInstance(pos.token1)
  //   console.log('results in here first')
  //   // const uniswapV3FactoryContract = useV3FactoryContract()
  //   const poolAddress = await uniswapV3FactoryContract.getPool(TokA.address, TokB.address, pos.fee);
  //   console.log('poolAddress', poolAddress)
  //   debugger;
  //   // useSingleContractMultipleData(uniswapV3FactoryContract, 'getPool', [inputs])

  //   // console.log('results in here',results)


  //   // const poolAddress = computePoolAddress({
  //   //   // factoryAddress: V3_CORE_FACTORY_ADDRESSES[chainId],
  //   //   factoryAddress: '0x855f2c70cf5cb1d56c15ed309a4dfefb88ed909e',
  //   //   tokenA: TokA ? TokA: getTokenInstanceNoToken(pos.token0),
  //   //   tokenB: TokB ? TokB : getTokenInstanceNoToken(pos.token1),
  //   //   fee: pos.fee,
  //   //   initCodeHashManualOverride: '0x146a9a61ada215bdbc7346c169e030cf383f441b85d40c70970f98c8aa070cd4'
  //   // })
  //   // console.log('V3_CORE_FACTORY_ADDRESSES[chainId]',V3_CORE_FACTORY_ADDRESSES[chainId])
  //   // console.log('getTokenInstanceNoToken(pos.token0)',getTokenInstanceNoToken(pos.token0),pos, 'poolAddress',poolAddress)
  //   // console.log('getTokenInstanceNoToken(pos.token1)',getTokenInstanceNoToken(pos.token1))
  //   // console.log('V3_CORE_FACTORY_ADDRESSES[chainId]', V3_CORE_FACTORY_ADDRESSES[chainId], 'TokA',TokA, 'TokB',TokB, 'pos.fee',pos.fee)

  //   multi.call(`${poolAddress}-${idx}`, poolAddress, 'slot0')
  //   // multi.call(`${poolAddress}-${idx}`, poolAddress, 'slot0')
  // })

  for (const [idx, pos] of positions.entries()) {
    const TokA = getTokenInstance(pos.token0);
    const TokB = getTokenInstance(pos.token1);

    try {
      const poolAddress = await uniswapV3FactoryContract.getPool(TokA.address, TokB.address, pos.fee);
      multi.call(`${poolAddress}-${idx}`, poolAddress, 'slot0');
    } catch (error) {
      console.error('Error fetching pool address:', error);
    }
  }


  const slot0s = await multi.execute('getPoolsInfoForPositions')

  return Object.entries(slot0s).map((slot) => {
    const split = slot[0].split('-')
    const idx = parseInt(split[1])
    const pos = positions[idx]

    return {
      pool: split[0],
      ...pos,
      ...slot[1],
    }
  })
}
