export var scales = {
    MD: "md",
    SM: "sm",
    XS: "xs",
    LG: "lg",
    TABLESMALL: "tablesmall",
    PAWG: "pawg",
    TOGGLE: "toggle",
    SWAPTOGGLE: "swaptoggle",
    MODALTOGGLE: "modaltoggle"
};
export var variants = {
    PRIMARY: "primary",
    ONE: "one",
    TWO: "two",
    THREE: "three",
    FOUR: "four",
    CALCULATOR: "calculator",
    CLASSIC: "classic",
    FILLGLOW: "fillglow",
    QUANTUM: "quantum",
    CONNECT: "connect",
    TOGGLE: "toggle",
    ARB: "arb",
    PERCENT: "percent",
    MADRID: "madrid",
    ARBBLUE: "arbblue",
    SECONDARY: "secondary",
    SECONDARYTRANS: "secondarytrans",
    TERTIARY: "tertiary",
    TEXT: "text",
    DANGER: "danger",
    SUBTLE: "subtle",
    SUCCESS: "success",
    LIGHT: "light",
    GASANDSLIPPAGE: "gasandslippage",
    GASANDSLIPPAGEOFF: "gasandslippageoff",
    MAX: "max"
};
