/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
  useMatchBreakpointsContext,
} from '@pancakeswap/uikit'
import Trans from 'components/Trans'
import useWalletDisconnect from 'hooks/useWalletDisconnect'
import { useRouter } from 'next/router'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import WalletModal, { WalletView } from './WalletModal'
import ProfileUserMenuItem from './ProfileUserMenuItem'
import WalletUserMenuItem from './WalletUserMenuItem'
import { isSupportedChain } from 'config/constants/chains'
import { VscDebugDisconnect } from "@react-icons/all-files/vsc/VscDebugDisconnect";
import { VscBeaker } from "@react-icons/all-files/vsc/VscBeaker";

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, chainId } = useWeb3React()
  const { isDesktop } = useMatchBreakpointsContext()
  const logout = useWalletDisconnect()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentMyWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const hasProfile = isInitialized && !!profile
  const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = account && isSupportedChain(chainId) === false

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentMyWalletModal()
    }
  }

  const UserMenuItems = () => {
    return (
      <>
        <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onPresentMyWalletModal} />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
          <VscBeaker size="24px" style={{ marginLeft: '8px' }} />
        </Flex>
        </UserMenuItem>
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <VscDebugDisconnect size="24px" style={{ marginLeft: '8px' }} />
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu account={account} avatarSrc={avatarSrc} text={userMenuText} variant={userMenuVariable}>
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <Flex alignItems="center" justifyContent="center"  height="100%" marginBottom={isDesktop? '3px' : '8px' }>
    <ConnectWalletButton variant="primary" scale={isDesktop? 'pawg' : 'sm' } />

    </Flex>

  )
}

export default UserMenu
