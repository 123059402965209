import { CHAIN_IDS_TO_ROUTE_NAMES, isSupportedChain } from 'config/constants/chains'
import { useEffect, useRef } from 'react'
import { NextRouter, useRouter } from 'next/router'

import useSelectChain from './useSelectChain'
import useActiveWeb3React from './useActiveWeb3React'

function getChainIdFromName(name: string): number | undefined {
  const entry = Object.entries(CHAIN_IDS_TO_ROUTE_NAMES).find(([, n]) => n === name)
  const chainId = entry?.[0]
  return chainId ? parseInt(chainId) : undefined
}

function getParsedChainId(router: NextRouter): number | undefined {
  const chain = router.query.chain
  if (!chain || typeof chain !== 'string') return

  return getChainIdFromName(chain)
}

export default function useSyncChainQuery() {
  const { chainId, isActive, account } = useActiveWeb3React()
  const chainIdRef = useRef(chainId)
  const accountRef = useRef(account)

  const selectChain = useSelectChain()
  const router = useRouter()

  useEffect(() => {
    // Update chainIdRef when the account is retrieved from Web3React
    if (account && account !== accountRef.current) {
      chainIdRef.current = chainId
      accountRef.current = account
    }
  }, [account, chainId])

  const urlChainId = getParsedChainId(router)

  useEffect(() => {
    // Change a user's chain on pageload if the connected chainId does not match the query param chain
    if (isActive && urlChainId && chainIdRef.current === chainId && chainId !== urlChainId) {
      selectChain(urlChainId)
    }
    // If a user has a connected wallet and has manually changed their chain, update the query parameter if it's supported
    else if (account && chainIdRef.current !== chainId && chainId !== urlChainId) {
      if (isSupportedChain(chainId)) {
        router.replace(router.pathname, {
          pathname: router.pathname,
          query: {
            chain: CHAIN_IDS_TO_ROUTE_NAMES[chainId],
          },
        })
      } else {
        router.replace(router.pathname)
      }
    }
    // If a user has a connected wallet and the chainId matches the query param chain, update the chainIdRef
    else if (isActive && chainId === urlChainId) {
      chainIdRef.current = urlChainId
    }
  }, [urlChainId, selectChain, router, isActive, chainId, account])
}
