import { ModalProvider } from "@pancakeswap/uikit";

const ModalProviderWrapper = ({ children }) => {
 return (
   <ModalProvider>
     {children}
   </ModalProvider>
 );
};

export default ModalProviderWrapper;
