import { serializeTokens } from 'utils/serializeTokens'
import { bscTokens } from './tokens'
import { SerializedFarmConfig, SerializedPoolConfig } from './types'
import poolsConfig from './pools'
import farmsConfig from './farms'

const serializedTokens = serializeTokens(bscTokens)

export const CAKE_BNB_LP_MAINNET = '0xA6efAE0C9293B4eE340De31022900bA747eaA92D'
// this is arx-usdce with test token as arx
const farms: SerializedFarmConfig[] = [
  {
    pid: 1,
    v1pid: 0,
    lpSymbol: 'ARX-USDCe LP',
    lpAddresses: {
      97: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
      56: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
      42161: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.usdce,
    classic: true,
    nftPoolAddress: {
      42161: '0xEFF788ABDd7877e20dc48D28B3F09c9AF2672531',
    },
  },

  {
    pid: 7,
    v1pid: 0,
    lpSymbol: 'USD+-DAI+ LP',
    lpAddresses: {
      97: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
      56: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
      42161: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
    },
    nftPoolAddress: {
      42161: '0xCab09D7F4b84aDa0972c2b7947Caeb36e8f64EcB',
    },
    token: serializedTokens.daip,
    quoteToken: serializedTokens.usdp,
    classic: true,

  },
  {
    pid: 8,
    v1pid: 0,
    lpSymbol: 'USD+-USDCe LP',
    lpAddresses: {
      97: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
      56: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
      42161: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
    },
    nftPoolAddress: {
      42161: '0x17233Dbb3af1925E320a05645aE3c727fa7a3905',
    },
    token: serializedTokens.usdp,
    quoteToken: serializedTokens.usdce,
    classic: true,
  },

  {
    pid: 15,
    v1pid: 0,
    lpSymbol: 'ARX-ETH LP',
    lpAddresses: {
      97: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
      56: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
      42161: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
    },
    nftPoolAddress: {
      42161: '0x8544890AABc7ef4A1a2CD427529A65E6b111112C',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
    classic: true,
  },
  {
    pid: 16,
    v1pid: 0,
    lpSymbol: 'DAI-DAI+ LP',
    lpAddresses: {
      97: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
      56: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
      42161: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
    },
    nftPoolAddress: {
      42161: '0x77aF0eEd0e0B71eD86a724454c95F091c3bB822d',
    },
    token: serializedTokens.daip,
    quoteToken: serializedTokens.dai,
    classic: true,
  },
  {
    pid: 17,
    v1pid: 0,
    lpSymbol: 'USD+-WETH LP',
    lpAddresses: {
      97: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
      56: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
      42161: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
    },
    nftPoolAddress: {
      42161: '0x0f2B79896B1f01B82fAFfbF047687A8b7727a57c',
    },
    token: serializedTokens.usdp,
    quoteToken: serializedTokens.wbnb,
    classic: true,
  },
  {
    pid: 21,
    v1pid: 0,
    lpSymbol: 'WBTC-ETH LP',
    lpAddresses: {
      97: '0x8a8d968c9534727118aa02965f16f6d398c14b1d',
      56: '0x8a8d968c9534727118aa02965f16f6d398c14b1d',
      42161: '0x8a8d968c9534727118aa02965f16f6d398c14b1d',
    },
    nftPoolAddress: {
      42161: '0xcEe1a6bb6dEd000F6AaA5223FaeB1DF62108Fa5C',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wbnb,
    quantum: true,
    wide: false,
    isNew: true,
  },
  {
    pid: 22,
    v1pid: 0,
    lpSymbol: 'WETH-USDCe LP',
    lpAddresses: {
      97: '0xcf8ea96ecfdb7800e1eb413bde4af6ce984ab079',
      56: '0xcf8ea96ecfdb7800e1eb413bde4af6ce984ab079',
      42161: '0xcf8ea96ecfdb7800e1eb413bde4af6ce984ab079',
    },
    nftPoolAddress: {
      42161: '0xdA982DD1D35680e24d522c757E31956842698000',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    wide: true,
  },
  {
    pid: 23,
    v1pid: 0,
    lpSymbol: 'USDT-USDCe LP',
    lpAddresses: {
      97: '0x3fb86a978530d9881627907b428e9ac9c02f9406',
      56: '0x3fb86a978530d9881627907b428e9ac9c02f9406',
      42161: '0x3fb86a978530d9881627907b428e9ac9c02f9406',
    },
    nftPoolAddress: {
      42161: '0x1A1E2321DF5e717a7717E86790CB956Bc60Ad1C7',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    stable: true,
  },
  {
    pid: 24,
    v1pid: 0,
    lpSymbol: 'DAI-USDCe LP',
    lpAddresses: {
      97: '0xc4602d1415f32ae23a72ad8cf4371272e25e2f22',
      56: '0xc4602d1415f32ae23a72ad8cf4371272e25e2f22',
      42161: '0xc4602d1415f32ae23a72ad8cf4371272e25e2f22',
    },
    nftPoolAddress: {
      42161: '0x58bF11D82e23f691658426dEcA2839D1714852E7',
    },
    token: serializedTokens.dai,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    stable: true,
  },
  {
    pid: 25,
    v1pid: 0,
    lpSymbol: 'WBTC-USDCe LP',
    lpAddresses: {
      97: '0x02ebfb3aac7934cdc6877d5ee8cf5e17e449a9a3',
      56: '0x02ebfb3aac7934cdc6877d5ee8cf5e17e449a9a3',
      42161: '0x02ebfb3aac7934cdc6877d5ee8cf5e17e449a9a3',
    },
    nftPoolAddress: {
      42161: '0x049175038dca452EaB12904fB20d843409309DA4',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    wide: false,
  },
  {
    pid: 26,
    v1pid: 0,
    lpSymbol: 'ARB-USDCe LP',
    lpAddresses: {
      97: '0xc1e2eda42ae3b3da68b622f03cd04ff3412467f7',
      56: '0xc1e2eda42ae3b3da68b622f03cd04ff3412467f7',
      42161: '0xc1e2eda42ae3b3da68b622f03cd04ff3412467f7',
    },
    nftPoolAddress: {
      42161: '0xF1Cba8849E0b9A6B5223aEc6E453f38cE55310f5',
    },
    token: serializedTokens.arb,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    wide: false,
  },
  {
    pid: 27,
    v1pid: 0,
    lpSymbol: 'ARB-WETH LP',
    lpAddresses: {
      97: '0x3369839e15d31b884dbd4e8e101fda0125fb08e8',
      56: '0x3369839e15d31b884dbd4e8e101fda0125fb08e8',
      42161: '0x3369839e15d31b884dbd4e8e101fda0125fb08e8',
    },
    nftPoolAddress: {
      42161: '0x0c198C30552e2c8D3A4A51f3d55E3522CB923874',
    },
    token: serializedTokens.arb,
    quoteToken: serializedTokens.wbnb,
    quantum: true,
    wide: false,
  },
  {
    pid: 28,
    v1pid: 0,
    lpSymbol: 'RDNT-WETH LP',
    lpAddresses: {
      97: '0xaca4edeee3a98a4311ec4c2a85c92163881f1644',
      56: '0xaca4edeee3a98a4311ec4c2a85c92163881f1644',
      42161: '0xaca4edeee3a98a4311ec4c2a85c92163881f1644',
    },
    nftPoolAddress: {
      42161: '0x4Fb1Ad8EB2F980B8F5616094595634AF835EFE30',
    },
    token: serializedTokens.rdnt,
    quoteToken: serializedTokens.wbnb,
    quantum: true,

  },
  {

    pid: 29,
    v1pid: 0,
    lpSymbol: 'FRAX-USDCe LP',
    lpAddresses: {
      97: '0x2d3d9d377c046c218e0f3b5a6bd17c9e673f1d8c',
      56: '0x2d3d9d377c046c218e0f3b5a6bd17c9e673f1d8c',
      42161: '0x2d3d9d377c046c218e0f3b5a6bd17c9e673f1d8c',
    },
    nftPoolAddress: {
      42161: '0x11205af33F2F7006bB932953b10Ab5EeD78105Ca',
    },
    token: serializedTokens.frax,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    classic: false,
    stable: true,
  },
  {
    pid: 30,
    v1pid: 0,
    lpSymbol: 'WETH-USDCe LP',
    lpAddresses: {
      97: '0xa1c443f7ae3cec5a61a163b98461af6dc4a98a90',
      56: '0xa1c443f7ae3cec5a61a163b98461af6dc4a98a90',
      42161: '0xa1c443f7ae3cec5a61a163b98461af6dc4a98a90',
    },
    nftPoolAddress: {
      42161: '0xfAf21795a2598DC7B40d57BE74429C52c5F02699',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    narrow: true,

  },
  {
    pid: 31,
    v1pid: 0,
    lpSymbol: 'GNS-WETH LP',
    lpAddresses: {
      97: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
      56: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
      42161: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
    },
    nftPoolAddress: {
      42161: '0x34546FC330c1E1Fdf5B8797CE929C9861C3E3925',
    },
    token: serializedTokens.gns,
    quoteToken: serializedTokens.wbnb,
    quantum: true,

  },
  {
    pid: 34,
    v1pid: 0,
    lpSymbol: 'FRAX-USD+ LP',
    lpAddresses: {
      97: '0xb0Fb1787238879171Edc30b9730968600D55762A',
      56: '0xb0Fb1787238879171Edc30b9730968600D55762A',
      42161: '0xb0Fb1787238879171Edc30b9730968600D55762A',
    },
    nftPoolAddress: {
      42161: '0xe876230251cc14Ec0027b2a4aFc46B4c262d13D0',
    },
    token: serializedTokens.frax,
    quoteToken: serializedTokens.usdp,
    quantum: false,
    classic: true,
  },
  {
    pid: 35,
    v1pid: 0,
    lpSymbol: 'FRAX-DAI+ LP',
    lpAddresses: {
      97: '0x306132b6147751B85E608B4C1EC452E111531eA2',
      56: '0x306132b6147751B85E608B4C1EC452E111531eA2',
      42161: '0x306132b6147751B85E608B4C1EC452E111531eA2',
    },
    nftPoolAddress: {
      42161: '0x56328f7138C3A4ebD2Bb1D126d28f62FcD5bac5d',
    },
    token: serializedTokens.frax,
    quoteToken: serializedTokens.daip,
    quantum: false,
    classic: true,
  },
  {
    pid: 36,
    v1pid: 0,
    lpSymbol: 'USDC-USDCe LP',
    lpAddresses: {
      97: '0x81e8be7795ed3d8619f037b8db8c80292332aa72',
      56: '0x81e8be7795ed3d8619f037b8db8c80292332aa72',
      42161: '0x81e8be7795ed3d8619f037b8db8c80292332aa72',
    },
    nftPoolAddress: {
      42161: '0xB9Bbe2fEf9587c7D868e27d4AE18b2EA5ee9ec1a',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    stable: true,
  },
  {
    pid: 37,
    v1pid: 0,
    lpSymbol: 'WETH-USDC LP',
    lpAddresses: {
      97: '0x1bde4a91d0ff7a353b511186768f4cc070874556',
      56: '0x1bde4a91d0ff7a353b511186768f4cc070874556',
      42161: '0x1bde4a91d0ff7a353b511186768f4cc070874556',
    },
    nftPoolAddress: {
      42161: '0x1Fa4f7e568671EbA175fDcc1f157D17877A6BeE7',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wbnb,
    quantum: true,
    narrow: true,
    isNew: true,

  },
  {
    pid: 41,
    v1pid: 0,
    lpSymbol: 'FRAX-WETH LP',
    lpAddresses: {
      97: '0xde553150ef951800d2c85b06ee3012113d7a262f',
      56: '0xde553150ef951800d2c85b06ee3012113d7a262f',
      42161: '0xde553150ef951800d2c85b06ee3012113d7a262f',
    },
    nftPoolAddress: {
      42161: '0xf0677E33Fa38A023ec798F97D195d85d19823e7C',
    },
    token: serializedTokens.frax,
    quoteToken: serializedTokens.wbnb,
    quantum: true,
    narrow: true,
    isNew: true,
  },
  {
    pid: 42,
    v1pid: 0,
    lpSymbol: 'frxETH-WETH LP',
    lpAddresses: {
      97: '0xd3b90a1780b58c59a4333a879c7df488876565f6',
      56: '0xd3b90a1780b58c59a4333a879c7df488876565f6',
      42161: '0xd3b90a1780b58c59a4333a879c7df488876565f6',
    },
    nftPoolAddress: {
      42161: '0xc74c0Fcb777521F3D8747f338a024e9F3fD91608',
    },
    token: serializedTokens.frxeth,
    quoteToken: serializedTokens.wbnb,
    quantum: true,
    narrow: true,
    isNew: true,
  },
  {
    pid: 43,
    v1pid: 0,
    lpSymbol: 'frxETH-FRAX LP',
    lpAddresses: {
      97: '0x1379fa99279129476a54108259da487294d53b97',
      56: '0x1379fa99279129476a54108259da487294d53b97',
      42161: '0x1379fa99279129476a54108259da487294d53b97',
    },
    nftPoolAddress: {
      42161: '0x436eE91dB228267c6a19b2A3F158fffCe5a4489c',
    },
    token: serializedTokens.frxeth,
    quoteToken: serializedTokens.frax,
    quantum: true,
    narrow: true,
    isNew: true,
  },
  {
    pid: 44,
    v1pid: 0,
    lpSymbol: 'ARB-USDC LP',
    lpAddresses: {
      97: '0xdbc35420ada6f048225d5f56e4744f467ae98d99',
      56: '0xdbc35420ada6f048225d5f56e4744f467ae98d99',
      42161: '0xdbc35420ada6f048225d5f56e4744f467ae98d99',
    },
    nftPoolAddress: {
      42161: '0xcA04e38375920397B7fd65C2801e9822e55c0b89',
    },
    token: serializedTokens.arb,
    quoteToken: serializedTokens.usdc,
    quantum: true,
    narrow: true,
    isNew: true,
    // Low Fee 
  },
  {
    pid: 45,
    v1pid: 0,
    lpSymbol: 'ARB-USDCe LP',
    lpAddresses: {
      97: '0x4b5cdc175ea9d2526bffd156aea3d22ff6145798',
      56: '0x4b5cdc175ea9d2526bffd156aea3d22ff6145798',
      42161: '0x4b5cdc175ea9d2526bffd156aea3d22ff6145798',
    },
    nftPoolAddress: {
      42161: '0xA51f160ED3B9e3184d02eBd6691f681475D8aA14',
    },
    token: serializedTokens.arb,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    narrow: true,
    isNew: true,
  },
  {
    pid: 46,
    v1pid: 0,
    lpSymbol: 'JEUR-USDCe LP',
    lpAddresses: {
      97: '0x3f8e688806829f9ba85abbd0c80399230cd80427',
      56: '0x3f8e688806829f9ba85abbd0c80399230cd80427',
      42161: '0x3f8e688806829f9ba85abbd0c80399230cd80427',
    },
    nftPoolAddress: {
      42161: '0x92F47465BEC5fCeae855287689CCCA0EAAb290Cf',
    },
    token: serializedTokens.jeur,
    quoteToken: serializedTokens.usdce,
    quantum: true,
    narrow: true,
    isNew: true,
  },
  {
    pid: 47,
    v1pid: 0,
    lpSymbol: 'JRT-WETH LP',
    lpAddresses: {
      97: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
      56: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
      42161: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
    },
    nftPoolAddress: {
      42161: '0x3d00AADBF48C5e66E023e542F6ffFDe28aC1e7Ea',
    },
    token: serializedTokens.jrt,
    quoteToken: serializedTokens.wbnb,
    quantum: false,
    classic: true,
    narrow: false,
    isNew: true,
  },
  {
    pid: 49,
    v1pid: 0,
    lpSymbol: 'UND-DAI LP',
    lpAddresses: {
      97: '0x05281948a2897d1a4a2e3a22889dc44aeb518669',
      56: '0x05281948a2897d1a4a2e3a22889dc44aeb518669',
      42161: '0x05281948a2897d1a4a2e3a22889dc44aeb518669',
    },
    nftPoolAddress: {
      42161: '0x8e3F40dF09AABdeeCc3135AB63E5d0163655BC31',
    },
    token: serializedTokens.und,
    quoteToken: serializedTokens.dai,
    quantum: true,
    classic: false,
    narrow: false,
    isNew: true,
  },
  {
    pid: 50,
    v1pid: 0,
    lpSymbol: 'WETH-USDC LP',
    lpAddresses: {
      97: '0xc4e56BFe61A5259CBd5Ad1B9C98c75A97C337B7B',
      56: '0xc4e56BFe61A5259CBd5Ad1B9C98c75A97C337B7B',
      42161: '0xc4e56BFe61A5259CBd5Ad1B9C98c75A97C337B7B',
    },
    nftPoolAddress: {
      42161: '0x78535f6513c3846A06cde91a0339812b33Df3B11',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.usdc,
    classic: true,
    isNew: true,
  },
  {
    pid: 51,
    v1pid: 0,
    lpSymbol: 'USDC-USDCe LP',
    lpAddresses: {
      97: '0x9c1CCb589C9B9960D03B008da31EA2bb0d1338D9',
      56: '0x9c1CCb589C9B9960D03B008da31EA2bb0d1338D9',
      42161: '0x9c1CCb589C9B9960D03B008da31EA2bb0d1338D9',
    },
    nftPoolAddress: {
      42161: '0xA05a3ba7d2Ea7b3F67874b96775B30BbE37013C7',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.usdc,
    classic: true,
    isNew: true,
  },
  {
    pid: 52,
    v1pid: 0,
    lpSymbol: 'ARB-USDC LP',
    lpAddresses: {
      97: '0x359491ad6caA061E29ee7EF3d47019bAC972B503',
      56: '0x359491ad6caA061E29ee7EF3d47019bAC972B503',
      42161: '0x359491ad6caA061E29ee7EF3d47019bAC972B503',
    },
    nftPoolAddress: {
      42161: '0xd0AC4BDE40138544dfdE4F320400Ef9Ec3b0642e',
    },
    token: serializedTokens.arb,
    quoteToken: serializedTokens.usdc,
    classic: true,
    isNew: true,
  },
  {
    pid: 53,
    v1pid: 0,
    lpSymbol: 'ARB-WETH LP',
    lpAddresses: {
      97: '0x39511b74722afE77d532Eb70632B4B59C559019b',
      56: '0x39511b74722afE77d532Eb70632B4B59C559019b',
      42161: '0x39511b74722afE77d532Eb70632B4B59C559019b',
    },
    nftPoolAddress: {
      42161: '0x9f82c9587b775eF1ac2236f895C14e9CD13B7131',
    },
    token: serializedTokens.arb,
    quoteToken: serializedTokens.wbnb,
    classic: true,
    isNew: true,
  },
  // LP Address is used to create the strategy with DefiEdge
  // The strategy is used for quantum liquidity and to create the PID in the OG MC 
  // The NFT pool must be created with the NFTPoolFactory - deployed  
  // The NFT pool Factory uses the strategy address 
  // The pool address is the farm  
]

export const getNftPoolConfigs = (chainId: number) => {
  return farms.filter((f) => f.nftPoolAddress && f.nftPoolAddress[chainId])
}

export const getNftPoolAddresses = (chainId: number) => {
  return getNftPoolConfigs(chainId).map((f) => f.nftPoolAddress[chainId])
}

export const getXFarmPoolConfig = (sousId: number): SerializedPoolConfig => {
  return poolsConfig.find((p) => p.sousId === sousId)
}

export const getXFarmBaseFarmConfig = (pid: number): SerializedFarmConfig => {
  return farmsConfig.find((p) => p.pid === pid)
}

export const getXFarmMergedConfigs = (pid: number) => {
  const poolConfig = getXFarmPoolConfig(pid)
  const farmConfig = getXFarmBaseFarmConfig(pid)
  const xFarmConfig = farms.find((f) => f.pid === pid)

  return {
    poolConfig,
    farmConfig,
    xFarmConfig,
  }
}

// Just token symbols (Eg. WETH-USDCe)
export const getFarmConfigBySymbol = (lpSymbol: string) => {
  // All currently are named as "TOKEN-TOKEN LP" so strip LP part for simpler argument passing
  const config = farmsConfig.find((p) => p.lpSymbol.split(' ')[0].toLowerCase() === lpSymbol.toLowerCase())
  if (!config) throw new Error(`No farm config for lpSymbol: ${lpSymbol}`)
  return config
}

export default farms
