import { Token, ChainId } from '@pancakeswap/sdk'
import { bscTokens } from './tokens'
import { CAKE_BNB_LP_MAINNET } from './farms'
import { Ifo } from './types'

export const cakeBnbLpToken = new Token(ChainId.BSC, CAKE_BNB_LP_MAINNET, 18, 'ARX-USDCe LP')

const ifos: Ifo[] = [
  // {
  //   id: 'Dummy Launch',
  //   // address: '0xF125DBbD0A8CCF970d5769Ca324fc2802af6b7BA',
  //   // address: '0x6A26D298f9207AD5652C74B6a33b49B93e24B01b',
  //   // address: '0x1aD2569e91B36E13B84aBF77653c520a3F042f61',
  //   address: '0x0e7f377791516aa234f743553b4CDbB2Ca30Be4A',
  //   isActive: true,
  //   name: 'DUMMY ($DUMMY)',
  //   // poolBasic: {
  //   //   saleAmount: '1000 DUMMY',
  //   //   raiseAmount: '$400,000',
  //   //   cakeToBurn: '$0',
  //   //   distributionRatio: 0.2,
  //   // },
  //   poolUnlimited: {
  //     saleAmount: '1000 DUMMY',
  //     raiseAmount: '$1,600,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.8,
  //   },
  //   // currency: bscTokens.cake,
  //   currency: bscTokens.dummyTokenLp,
  //   token: bscTokens.dummyToken,
  //   releaseBlockNumber: 1685412254,
  //   campaignId: '511300000',
  //   articleUrl:
  //     'https://pancakeswap.finance/voting/proposal/bafkreibomj5nilvyckdro7ztmm62syt55dcfnonxs63ji6hm2ijq35lru4',
  //   tokenOfferingPrice: 0.9,
  //   tokenOfferingPricePublic: 1,
  //   version: 3.0,
  //   twitterUrl: 'https://twitter.com/test',
  //   description:
  //     'Dummy test',
  //   vestingTitle: 'Info about $DUMMY',
  // },
]

export default ifos
