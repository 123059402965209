import { dark, MatchBreakpointsProvider } from '@pancakeswap/uikit'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { ThemeProvider } from 'styled-components'
import { ToastsProvider } from 'contexts/ToastsContext'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { Store } from '@reduxjs/toolkit'
import { ThemeProvider as NextThemeProvider, useTheme as useNextTheme } from 'next-themes'
import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import { LanguageProvider } from 'contexts/Localization'

import dynamic from 'next/dynamic'
// import { YieldBoosterProvider } from 'views/XToken/yield-booster/hooks/useYieldBooster'

import Web3Provider from 'components/Web3Provider'
import ModalProvider from 'components/Wrappers/ModalProviderWrapper'

const StyledThemeProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { resolvedTheme } = useNextTheme()
  // @ts-ignore
  return <ThemeProvider theme={resolvedTheme === 'dark' ? dark : dark} {...props} />
}

const Providers: React.FC<{ store: Store }> = ({ children, store }) => {
  console.log('store',store)
  return (
    <Web3Provider>
      <Provider store={store}>
        <MatchBreakpointsProvider>
          <ToastsProvider>
            <NextThemeProvider>
              <StyledThemeProvider>
                <LanguageProvider>
                  <BlockNumberProvider>
                    <SWRConfig
                      value={{
                        use: [fetchStatusMiddleware],
                      }}
                    >
                      {/* <YieldBoosterProvider> */}
                      <ModalProvider>{children}</ModalProvider>
                      {/* </YieldBoosterProvider> */}
                    </SWRConfig>
                  </BlockNumberProvider>
                </LanguageProvider>
              </StyledThemeProvider>
            </NextThemeProvider>
          </ToastsProvider>
        </MatchBreakpointsProvider>
      </Provider>
    </Web3Provider>
  )
}

export default Providers
