import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId[// ETHEREUM = 1,
    // RINKEBY = 4,
    "BSC"] = 42161] = "BSC";
    ChainId[ChainId["ARBITRUM"] = 42161] = "ARBITRUM";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
// export const FACTORY_ADDRESS_OLD = '0x1C6E968f2E6c9DEC61DB874E28589fd5CE3E1f2c'
export var FACTORY_ADDRESS = "0x1C6E968f2E6c9DEC61DB874E28589fd5CE3E1f2c";
var _obj;
// export const FACTORY_ADDRESS = '0x855F2c70cf5cb1D56C15ed309a4DfEfb88ED909E'
export var FACTORY_ADDRESS_MAP = (_obj = {}, // [ChainId.BSC]: FACTORY_ADDRESS,
_define_property(_obj, ChainId.ARBITRUM, FACTORY_ADDRESS), _define_property(_obj, ChainId.BSC_TESTNET, "0x6725f303b657a9451d8ba641348b6761a6cc7a17"), _obj);
// this it? --- this is the old one
export var INIT_CODE_HASH = "0x724c966696ee786bca53d9ebb15f34f4961a6a2d55b17599d6dd4681a335275a";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH), _define_property(_obj1, ChainId.BSC_TESTNET, "0x724c966696ee786bca53d9ebb15f34f4961a6a2d55b17599d6dd4681a335275a"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var FEES_NUMERATOR = JSBI.BigInt(9975);
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
