import { ChainId } from '@pancakeswap/sdk'
import SID, { getSidAddress } from '@siddomains/sidjs'
import { useCallback } from 'react'
import { bscRpcProvider } from 'utils/providers'
import { useWeb3React } from '@web3-react/core'

/**
 * Reverse resolves an address and returns the name
 * If bnb name doesn't exist, returns null.
 * @param address active user address
 * @param chainId chain ID
 * @param provider provider
 * @returns reverse resolved name
 */
async function getName(address, chainId, provider) {
  if (![ChainId.BSC, ChainId.BSC_TESTNET].includes(chainId)) {
    return address
  }
  try {
    const id = new SID({
      provider,
      sidAddress: getSidAddress(`${chainId}`),
    })
    const sidName = await id.getName(address.toLowerCase())
    return sidName.name

    // return name?.name ?? address
  } catch (error) {
    return address
  }
}

/**
 * Returns promises of SID resolving and reversed resolving.
 * @returns {Promise}
 */
export const useSid = () => {
  const { chainId } = useWeb3React()
  //   const provider = useProvider({ chainId })
  const provider = bscRpcProvider

  const getSidName = useCallback(
    async (address) => {
      return getName(address, chainId, provider)
    },
    [chainId, provider],
  )

  return { getSidName }
}
