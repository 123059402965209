import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: none;\n  position: fixed; \n  top: 0; \n  left: 0; \n  z-index: 15; \n  flex-direction: column; \n  justify-content: center;\n  align-items: center;\n  width: 155px; \n  height: 100%; \n  background: ",
        ";\n  border-right: 0px solid #bcbcbe; \n  border-top-right-radius: 12px; \n  transform: translate3d(0, 0, 0);\n  @media (min-width: 1000px) {\n    display: flex; \n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  position: fixed; \n  top: 0; \n  left: 0; \n  z-index: 15; \n  flex-direction: row; \n  justify-content: space-between;\n  align-items: flex-end;\n  width: 100%;\n  height: 48px;\n  border-bottom-right-radius: 12px; \n  border-bottom-left-radius: 12px; \n  border-bottom: 1px;\n  border-style: solid;  \n  border-color: ",
        ";\n  background: ",
        ";\n  transform: translate3d(0, 0, 0);\n  padding-left: 2px; \n  padding-right: 2px; \n  @media (min-width: 1000px) {\n    padding-right: 16px;\n    border-bottom: 0px solid; \n}\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var LeftNav = styled.nav.withConfig({
    componentId: "sc-f5a86b51-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.gradients.navgrad;
});
export var TopNav = styled.nav.withConfig({
    componentId: "sc-f5a86b51-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.colors.teal;
}, function(param) {
    var theme = param.theme;
    return theme.colors.gradients.navgrad2;
});
