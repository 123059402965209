import { useEffect } from 'react'
import { useAppDispatch } from '../state'
import { clearUserStates } from '../utils/clearUserStates'
import useActiveWeb3React from './useActiveWeb3React'
import { useWeb3React } from '@web3-react/core'

export const useAccountEventListener = () => {
  const { account, chainId, connector } = useWeb3React()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (account && connector) {
      const handleUpdateEvent = () => {
        clearUserStates(dispatch, chainId)
      }

      const handleDeactiveEvent = () => {
        clearUserStates(dispatch, chainId, true)
      }

      connector.provider.on('Web3ReactDeactivate', handleDeactiveEvent)
      connector.provider.on('Web3ReactUpdate', handleUpdateEvent)

      return () => {
        connector.provider.removeListener('Web3ReactDeactivate', handleDeactiveEvent)
        connector.provider.removeListener('Web3ReactUpdate', handleUpdateEvent)
      }
    }
    return undefined
  }, [account, chainId, dispatch, connector])
}
