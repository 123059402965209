import { flexColumnNoWrap, flexRowNoWrap, Spinner } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { Connection } from 'connection/types'
import Image from 'next/image'
import styled, { useTheme } from 'styled-components'

const OptionCardLeft = styled.div`
  ${flexColumnNoWrap};
  flex-direction: row;
  align-items: center;
`

const OptionCardClickable = styled.button<{ selected: boolean }>`
  align-items: center;
  background: transparent;
  cursor: pointer;
  border-radius: 8px; 
  border: none;  
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${({ disabled, selected }) => (disabled && !selected ? '0.5' : '1')};
  padding: 8px; 
  margin-bottom: 8px; 
  transition: 1;
  &:hover:not(:disabled) {
    background: transparent;
  }
`

const HeaderText = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.colors.text : ({ theme }) => theme.colors.text)};
  font-size: 18px;
  font-weight: 300;
  padding: 0 8px;
`
const IconWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  img {
    ${({ theme }) => !theme.isDark && `border: 1px solid ${theme.colors.gradients.basedsexgrayflip}`};
    border-radius: 12px;
    max-width: 40px;
    max-height: 40px;
  }
  & > img,
  span {
    height: 40px;
    width: 40px;
  }
`

const Wrapper = styled.div<{ disabled: boolean }>`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gradients.basedsexgrayflip};

  &:hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.gradients.basedsexgrayflip};
  }
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.gradients.basedsexgrayflip};
  }
`

interface OptionProps {
  connection: Connection
}
export default function Option({ connection }: OptionProps) {
  const { activationState, tryActivation } = useActivationState()
  const { chainId } = useWeb3React()
  const activate = () => tryActivation(connection, () => console.log('tryActivation: Success'), chainId)
  const isSomeOptionPending = activationState.status === ActivationStatus.PENDING
  const isCurrentOptionPending = isSomeOptionPending && activationState.connection.type === connection.type
  const { isDark: isDarkMode } = useTheme()

  return (
    <Wrapper disabled={isSomeOptionPending}>
      <OptionCardClickable
        disabled={isSomeOptionPending}
        onClick={activate}
        selected={isCurrentOptionPending}
        data-testid={`wallet-option-${connection.type}`}
      >
        <OptionCardLeft>
          <IconWrapper style={{maxWidth: 50}}>
            <Image src={connection.getIcon?.(isDarkMode)} alt={connection.getName()} />
          </IconWrapper>
          <HeaderText>{connection.getName()}</HeaderText>
        </OptionCardLeft>
        {isCurrentOptionPending && <Spinner />}
      </OptionCardClickable>
    </Wrapper>
  )
}
