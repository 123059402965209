import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCurrentBlock } from 'state/block/hooks'
import { ToastDescriptionWithTx } from 'components/Toast'
import useToast from 'hooks/useToast'
import { AppState, useAppDispatch } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'

export function shouldCheck(
  currentBlock: number,
  tx: { addedTime: number; receipt?: any; lastCheckedBlockNumber?: number },
): boolean {
  // dont check if this bitch alr has a receipt. 
  if (tx.receipt) {
    return false;
  }

  // if tx isnt checked before, by all means proceed. 
  if (!tx.lastCheckedBlockNumber) {
    return true;
  }

  const blocksSinceCheck = currentBlock - tx.lastCheckedBlockNumber;
  // dont check if there are no new blocks since the last check.
  if (blocksSinceCheck < 1) {
    return false;
  }
  // Calculate time elapsed since the tx was addedc
  const currentTime = new Date().getTime();
  const hoursPending = (currentTime - tx.addedTime) / 1000 / 60 / 60;
  const minutesPending = (currentTime - tx.addedTime) / 1000 / 60;

  // cut it out if transaction has been pending for more than 2 hours.this is designed to prevent 
  //ongoing bullshit api calls 
  if (hoursPending > 2) {
    return false;
  }

  // Check for a new receipt every 30 blocks if pending for more than hr.
  if (minutesPending > 60) {
    return blocksSinceCheck > 29;
  }

  // check every 10 blocks if pending for more than 5 min. 
  if (minutesPending > 5) {
    return blocksSinceCheck > 9;
  }

  // for new shit check every 2 blocks. 
  return blocksSinceCheck > 1;
}

export default function Updater(): null {
  const { library, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const currentBlock = useCurrentBlock()
  const dispatch = useAppDispatch()
  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions)
  const transactions = useMemo(() => (chainId ? state[chainId] ?? {} : {}), [chainId, state])
  const { toastError, toastSuccess } = useToast()
  useEffect(() => {
    if (!chainId || !library || !currentBlock) return

    Object.keys(transactions)
      .filter((hash) => shouldCheck(currentBlock, transactions[hash]))
      .forEach((hash) => {
        library
          .getTransactionReceipt(hash)
          .then((receipt) => {
            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                }),
              )
              const toast = receipt.status === 1 ? toastSuccess : toastError
              toast(t('Transaction receipt'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
            } else {
              dispatch(checkedTransaction({ chainId, hash, blockNumber: currentBlock }))
            }
          })
          // .catch((error) => {
          //   console.error(`failed to check transaction hash: ${hash}`, error)
          // })
      })
  }, [chainId, library, transactions, currentBlock, dispatch, toastSuccess, toastError, t])

  return null
}
