export default {
  xToken: {
    42161: '0xa954A31137fBe5c2D384A0067DE042bAA58b3403',
  },
  xARXStaker: {
    42161: '0x4cB69d9D05f7a4b30D99FCe79e3841173C8D2d2E',
  },
  ChefRamsey: {
    42161: '0x282fdb7A2876Ade5C027061D6FA5D7724AE1b2e5',
  },
  masterChef: {
    97: '0xd2bcFd6b84E778D2DE5Bb6A167EcBBef5D053A06',
    42161: '0xd2bcFd6b84E778D2DE5Bb6A167EcBBef5D053A06',
    // 42161: '0x5693a243b0968e4218faF590bdA4607E65Ff499d',old
  },

  masterChefV1: {
    97: '0xd2bcFd6b84E778D2DE5Bb6A167EcBBef5D053A06',
    42161: '0xd2bcFd6b84E778D2DE5Bb6A167EcBBef5D053A06',
  },
  // not right addies not used yet
  yieldBooster: {
    97: '0x0F5433c9f1c2E86588304eD09BC79AbEcc89e0de',
    42161: '0x0F5433c9f1c2E86588304eD09BC79AbEcc89e0de',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    42161: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  //souschef is not in use
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    42161: '0x5790c3534F30437641541a0FA04C992799602998',
  },
  //lotteryv2 is not in use

  multiCall: {
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    42161: '0x98cb0A82D20414d57ba567e874f92E150e836Cd4',
  },
  // correct version

  pancakeProfile: {
    42161: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    42161: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    42161: '0x1C6E968f2E6c9DEC61DB874E28589fd5CE3E1f2c',
    97: '0x1C6E968f2E6c9DEC61DB874E28589fd5CE3E1f2c',
  },
  claimRefund: {
    42161: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    // 42161: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    42161: '0x4736Fb92720F605708d68e9468c28A94C5852213',
    97: '0x4736Fb92720F605708d68e9468c28A94C5852213',
    // 97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    42161: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    42161: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    42161: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
  },
  tradingCompetitionMobox: {
    42161: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
  },
  tradingCompetitionMoD: {
    42161: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
  },
  easterNft: {
    42161: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  //
  // ARB POOL
  cakeVault: {
    42161: '0xee1D57aCE6350D70E8161632769d29D34B2FbfC8',
    97: '',
  },
  // FLEXIBLE ARBDEX POOL
  cakeFlexibleSideVault: {
    42161: '0x489732e4D028e500C327F1424931d428Ba695dF6',
    97: '',
  },
  //this is flexible staking weird tack on contract ^^ updated
  predictionsBNB: {
    42161: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  predictionsCAKE: {
    42161: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '',
  },
  chainlinkOracleBNB: {
    42161: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  chainlinkOracleCAKE: {
    42161: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '',
  },
  predictionsV1: {
    42161: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '',
  },
  bunnySpecialCakeVault: {
    42161: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    42161: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    42161: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    42161: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    42161: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    42161: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    42161: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    42161: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    42161: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  // TODO: multi
  zap: {
    42161: '0x97C397b23836EbAA1f5602894cAA469537E85f21',
    97: '0x97C397b23836EbAA1f5602894cAA469537E85f21',
  },
  iCake: {
    42161: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
  },
  weth: {
    42161: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    97: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  },
}
