import { SerializedFarmConfig } from 'config/constants/types'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import { BIG_ZERO, BIG_TWO } from '../../utils/bigNumber'
import { fetchPublicFarmsData, getAllQuantumData } from './fetchPublicFarmData'
import { fetchMasterChefData } from './fetchMasterChefData'
import { SerializedFarm } from '../types'
import multicall from 'utils/multicall'
import masterchefABI from 'config/abi/masterchef.json'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { getBalanceAmount } from 'utils/formatBalance'
import { ethersToBigNumber } from 'utils/bigNumber'
import farmsConfig from 'config/constants/farms'
import getFarmsPrices from './getFarmsPrices'

export const fetchBaseFarmData = async (poolIds: number[]): Promise<[SerializedFarm[], number, number, number]> => {
  const masterChefAddress = getMasterChefAddress()
  const calls = [
    {
      address: masterChefAddress,
      name: 'poolLength',
    },
    {
      address: masterChefAddress,
      name: 'arxPerSec',
    },
    {
      address: masterChefAddress,
      name: 'WETHPerSec',
    },
  ]

  const [[poolLength], [arxPerSec], [WETHPerSec]] = await multicall(masterchefABI, calls)

  const arxPerSecBN = getBalanceAmount(ethersToBigNumber(arxPerSec))
  const WETHPerSecBN = getBalanceAmount(ethersToBigNumber(WETHPerSec))
  const farmsToFetch = farmsConfig.filter((farmConfig) => poolIds.includes(farmConfig.pid))
  const farmsCanFetch = farmsToFetch.filter((f) => poolLength.gt(f.pid))

  const farms = await fetchFarms(farmsCanFetch)
  const farmsWithPrices = getFarmsPrices(farms)
  return [farmsWithPrices, poolLength.toNumber(), arxPerSecBN.toNumber(), WETHPerSecBN.toNumber()]
}

const fetchFarms = async (farmsToFetch: SerializedFarmConfig[]): Promise<SerializedFarm[]> => {
  const [farmResult, masterChefResult] = await Promise.all([
    fetchPublicFarmsData(farmsToFetch),
    fetchMasterChefData(farmsToFetch),
  ])

  console.log('farmsToFetch',farmsToFetch)
  const getPoolsQuantumData = await getAllQuantumData(farmsToFetch)

  return farmsToFetch.map((farm, index) => {
    const [
      tokenBalanceLP,
      quoteTokenBalanceLP,
      lpTokenBalanceMC,
      lpTotalSupply,
      [tokenDecimals],
      [quoteTokenDecimals],
    ] = farmResult[index]

    const [info, arxTotalAllocPoint, WETHTotalAllocPoint] = masterChefResult[index]
    const lpTotalSupplyBN = new BigNumber(lpTotalSupply)

    // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
    const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(lpTotalSupplyBN)

    // Raw amount of token in the LP, including those not staked
    const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(getFullDecimalMultiplier(tokenDecimals))
    const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(getFullDecimalMultiplier(quoteTokenDecimals))

    // Amount of quoteToken in the LP that are staked in the MC
    const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

    // Total staked in LP, in quote token value
    let lpTotalInQuoteToken
    if (farm.pid === 0)
      lpTotalInQuoteToken = new BigNumber(info.totalDeposit?._hex).div(getFullDecimalMultiplier(quoteTokenDecimals))
    else
      lpTotalInQuoteToken = farm.quantum
        ? new BigNumber(info.totalDeposit?._hex).div(getFullDecimalMultiplier(18))
        : quoteTokenAmountMc.times(BIG_TWO)

    const arxAllocPoint = info ? new BigNumber(info.arxAllocPoint?._hex) : BIG_ZERO
    const arxPoolWeight = arxTotalAllocPoint ? arxAllocPoint.div(new BigNumber(arxTotalAllocPoint)) : BIG_ZERO
    const WETHAllocPoint = info ? new BigNumber(info.WETHAllocPoint?._hex) : BIG_ZERO
    const WETHPoolWeight = WETHTotalAllocPoint ? WETHAllocPoint.div(new BigNumber(WETHTotalAllocPoint)) : BIG_ZERO
    const rewardPerBlock = 10
    const earnLabel = WETHPoolWeight.eq(BIG_ZERO) ? 'ARX + Fees' : 'ARX + WETH + Fees'
    const endBlock = 1231231231

    const quantumStrategy = getPoolsQuantumData.find(
      (q) => q.strategy?.address.toLowerCase() === farm.lpAddresses[42161].toLowerCase(),
    )

    return {
      ...farm,
      token: farm.token,
      quoteToken: farm.quoteToken,
      tokenAmountTotal: tokenAmountTotal.toJSON(),
      quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
      lpTotalSupply: lpTotalSupplyBN.toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
      tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
      arxPoolWeight: arxPoolWeight.toJSON(),
      WETHPoolWeight: WETHPoolWeight.toJSON(),
      multiplier: `${arxAllocPoint.plus(WETHAllocPoint).div(100).toString()}X`,
      arxMultiplier: `${arxAllocPoint.div(100).toString()}X`,
      WETHMultiplier: `${WETHAllocPoint.div(100).toString()}X`,
      dual: { rewardPerBlock, earnLabel, endBlock },
      quantumStrategy: farm.quantum ? getPoolsQuantumData : null,
      quantumStrategies: farm.quantum ? getPoolsQuantumData : null,
      quantumSharePrice: quantumStrategy ? quantumStrategy.sharePrice : 0,
    }
  })
}

export default fetchFarms
