import { Flex, Text } from "@pancakeswap/uikit"
import styled from "styled-components"
import Rodal from "rodal"
import 'rodal/lib/rodal.css'; 

export const ModeRodal = styled(Rodal)`
  background-color: ${({ theme }) => theme.colors.black};
  border-color: ${({ theme }) => theme.colors.lightgray};
  border-radius: 24px; 
  box-shadow: 0 0 96px ${({ theme }) => theme.colors.background};
  border-width: 1px; 
  border-style: inset; 

`


export const RodalTitle = styled(Flex)`
  font-size: 24px; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  margin-top: 12px; 
  margin-bottom: 1rem; 
  @media (min-width: 1000px) {
    font-size: 32px; 
  }
`
export const RodalBetween = styled(Flex)`
  padding: 4px; 
  flex-direction: column;  

  justify-content: space-between; 
  width: 100%; 
  height: 100%; 
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const RodalBody = styled(Flex)`
  padding: 4px; 
  flex-direction: column;  
  justify-content: space-between; 



`