import { Button, ButtonProps } from '@pancakeswap/uikit'
import Trans from './Trans'
import { useWalletModal } from 'components/WalletModal'
import { useCallback, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { onPresentConnectModal, onDismissModal } = useWalletModal()
  const { account } = useWeb3React()

  useEffect(() => {
    if (account) onDismissModal()
  }, [account, onDismissModal])

  const handleClick = useCallback(() => {
    onPresentConnectModal()
  }, [onPresentConnectModal])

  return (
    <Button variant="primary" padding="12px" onClick={handleClick} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </Button>
  )
}

export default ConnectWalletButton
