import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { updateSelectedWallet } from 'state/user/actions'
import useActiveWeb3React from './useActiveWeb3React'
import { deletePersistedConnectionMeta } from 'connection/meta'

export default function useWalletDisconnect() {
  const { connector } = useActiveWeb3React()
  const dispatch = useAppDispatch()

  const disconnect = useCallback(() => {
    console.log(connector)
    if (connector && connector.deactivate) {
      connector.deactivate()
    }

    connector.resetState()
    deletePersistedConnectionMeta()
    dispatch(updateSelectedWallet({ wallet: undefined }))
  }, [connector, dispatch])

  return disconnect
}
