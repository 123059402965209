import BigNumber from 'bignumber.js'
import { SerializedAdditonalRewardInfo, SerializedLockedVaultUser, SerializedVaultUser } from 'state/types'
import { getCakeVaultAddress } from 'utils/addressHelpers'
import { Call, multicallv2 } from 'utils/multicall'
import { getCakeFlexibleSideVaultV2Contract, getXToken, getxARXStaker } from '../../utils/contractHelpers'
import { formatEther, formatUnits } from '@ethersproject/units'
import { EthersBigNumber } from 'utils/types'
import { getTokenInstance } from 'config/constants/token-list'

import cakeVaultAbi from 'config/abi/cakeVaultV2.json'
import xarxStakerAbi from 'config/abi/xARXStaker.json'
import xArxABI from 'config/abi/xARX.json'
import { ChainId } from '@pancakeswap/sdk'

const cakeVaultAddress = getCakeVaultAddress()
const flexibleSideVaultContract = getCakeFlexibleSideVaultV2Contract()

export const fetchVaultUser = async (account: string): Promise<SerializedLockedVaultUser> => {
  try {
    const calls = ['userInfo', 'calculatePerformanceFee', 'calculateOverdueFee'].map((method) => ({
      address: cakeVaultAddress,
      name: method,
      params: [account],
    }))

    const [userContractResponse, [currentPerformanceFee], [currentOverdueFee]] = await multicallv2(cakeVaultAbi, calls)
    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      arxAtLastUserAction: new BigNumber(userContractResponse.arxAtLastUserAction.toString()).toJSON(),
      userBoostedShare: new BigNumber(userContractResponse.userBoostedShare.toString()).toJSON(),
      locked: userContractResponse.locked,
      lockEndTime: userContractResponse.lockEndTime.toString(),
      lockStartTime: userContractResponse.lockStartTime.toString(),
      lockedAmount: new BigNumber(userContractResponse.lockedAmount.toString()).toJSON(),
      currentPerformanceFee: new BigNumber(currentPerformanceFee.toString()).toJSON(),
      currentOverdueFee: new BigNumber(currentOverdueFee.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      arxAtLastUserAction: null,
      userBoostedShare: null,
      lockEndTime: null,
      lockStartTime: null,
      locked: null,
      lockedAmount: null,
      currentPerformanceFee: null,
      currentOverdueFee: null,
    }
  }
}

export const fetchFlexibleSideVaultUser = async (account: string): Promise<SerializedVaultUser> => {
  try {
    const userContractResponse = await flexibleSideVaultContract.userInfo(account)
    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      arxAtLastUserAction: new BigNumber(userContractResponse.arxAtLastUserAction.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      arxAtLastUserAction: null,
    }
  }
}

export const fetchxARXStakerUser = async (account: string) => {
  try {
    const staker = getxARXStaker()
    const xARX = getXToken(ChainId.ARBITRUM)

    const calls: Call[] = [
      {
        address: staker.address,
        name: 'userInfo',
        params: [account],
      },
      {
        address: staker.address,
        name: 'pendingReward',
        params: [account],
      },
      {
        address: staker.address,
        name: 'getRewardTokensInfo',
      },
    ]

    const xarxCalls: Call[] = [
      {
        address: xARX.address,
        name: 'allowance',
        params: [account, staker.address],
      },
      {
        address: xARX.address,
        name: 'balanceOf',
        params: [account],
      },
    ]

    const [userDeposit, xtokenInfo] = await Promise.all([
      multicallv2(xarxStakerAbi, calls, {
        requireSuccess: false,
      }),
      multicallv2(xArxABI, xarxCalls, {
        requireSuccess: false,
      }),
    ])

    const info = userDeposit
    const xinfo = xtokenInfo.flat()

    const allowance = formatEther(xinfo[0])
    const stakingTokenBalance = xinfo[1].toString()

    const stakedBalance = info[0].amount.toString()
    const rewardAmounts: EthersBigNumber[] = info[1].rewardAmounts
    const rewardTokens: string[] = info[1].tokens

    let pendingReward

    const additionalPendingRewards: SerializedAdditonalRewardInfo[] = rewardTokens.map(
      (tokenAddress, idx): SerializedAdditonalRewardInfo => {
        const token = getTokenInstance(tokenAddress)
        const pendingAmount = formatUnits(rewardAmounts[idx], token.decimals)

        if (token.symbol === 'USDCe') pendingReward = pendingAmount

        return {
          ...token,
          pendingAmount,
          apr: 0, // filled in later
        }
      },
    )

    return {
      stakedBalance,
      allowance,
      allowancesAutocompounder: '0',
      stakingTokenBalance,
      pendingReward,
      additionalPendingRewards,
    }
  } catch (error) {
    console.log(error)
  }
}
