/* eslint-disable @typescript-eslint/no-unused-vars */
import { MoreIcon, MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { RiHome3Fill } from 'react-icons/ri'
import { ContextApi } from 'contexts/Localization/types'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { ChainId } from '@pancakeswap/sdk'
import { MdSwapHoriz } from 'react-icons/md'
import { GiDroplets, GiMoneyStack, GiFarmer, GiArchBridge } from 'react-icons/gi'
import { BsDatabaseFillUp } from 'react-icons/bs'
import { CiVault } from 'react-icons/ci'
import styled from 'styled-components'

const WhiteMoreIcon = styled(MoreIcon)`
  path {
    fill: #D8FAF1;
  }
`
export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean, showItemsOnDesktop?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean, showItemsOnDesktop?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Home'),
      icon: RiHome3Fill,
      fillIcon: RiHome3Fill,
      href: '/',
      showItemsOnMobile: false,
      showItemsOnDesktop: false, 
      items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('Swap'),
      icon: MdSwapHoriz,
      href: '/swap',
      showItemsOnMobile: true,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
          icon: GiDroplets,
          supportChainIds: [ChainId.BSC],
          showItemsOnMobile: true,
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        },
        {
          label: t('Perpetual'),
          href: 'https://perpetual.arbidex.fi/',
          confirmModalId: 'usCitizenConfirmModal',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },

      ].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('Liquidity'),
      href: '/liquidity',
      icon: GiDroplets,
      supportChainIds: [ChainId.BSC],
      showItemsOnMobile: false,
      items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('Farm'),
      href: '/farms',
      icon: GiFarmer,
      supportChainIds: [ChainId.BSC],
      showItemsOnMobile: false,
      items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('Earn'),
      href: '/pools',
      icon: BsDatabaseFillUp,
      supportChainIds: [ChainId.BSC],
      showItemsOnMobile: false,
      items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('xARX'),
      href: '/XARX',
      icon: BsDatabaseFillUp,
      supportChainIds: [ChainId.BSC],
      showOnMobile: false, 
      items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('NFT'),
      icon: MdSwapHoriz,
      href: 'https://nfts.arbidex.fi',
      showOnMobile: false,
      items: [
        // {
        //   label: t('ArbiDex NFTs'),
        //   href: 'https://nfts.arbidex.fi',
        //   icon: GiDroplets,
        //   supportChainIds: [ChainId.BSC],
        //   showItemsOnMobile: true,
        //   items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        // },
        {
        label: t('Marketplace'),
        href: 'https://marketplace.arbidex.fi',
        icon: BsDatabaseFillUp,
        supportChainIds: [ChainId.BSC],
        showOnMobile: false, 
        
        },
      ].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
 
    // {
    //   label: t('Landing'),
    //   href: 'https://landing.arbidex.fi',
    //   icon: BsDatabaseFillUp,
    //   supportChainIds: [ChainId.BSC],
    //   showOnMobile: false, 
    //   items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    // },
    {
      label: '', 
      href: '',
      icon: WhiteMoreIcon,
      supportChainIds: [ChainId.BSC],
      showOnMobile: false, 
      items: [
        {
          label: t('Landing'),
          href: 'https://landing.arbidex.fi',
          icon: BsDatabaseFillUp,
          supportChainIds: [ChainId.BSC],
          showItemsOnMobile: true,
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        },
        {
          label: t('Launchpad'),
          href: '/launchpad',
          icon: BsDatabaseFillUp,
          supportChainIds: [ChainId.BSC],
          showItemsOnMobile: true,
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        },
        {
          label: t('Bridge'),
          href: '/bridge',
          icon: GiArchBridge,
          supportChainIds: [ChainId.BSC],
          showItemsOnMobile: false,
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        },
      ].filter((item) => filterItemBySupportChainId(item, chainId)),
    },
    {
      label: t('More'),
      icon: WhiteMoreIcon,
      href: '/swap',
      showItemsOnMobile: true,
      showItemsOnDesktop: false, 
      items: [
        {
          label: t('xARX'),
          href: '/xarx',
          supportChainIds: [ChainId.BSC],
            items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        },
        // {
        //   label: t('ArbiDex NFTs'),
        //   href: 'https://nfts.arbidex.fi',
        //   supportChainIds: [ChainId.BSC],
        //     items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        // },
        {
          label: t('NFT Marketplace'),
          href: 'https://marketplace.arbidex.fi',
          supportChainIds: [ChainId.BSC],
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        },
        {
          label: t('Landing'),
          href: 'https://landing.arbidex.fi',
          supportChainIds: [ChainId.BSC],
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
        },
      
      {
        label: t('Launchpad'),
        href: '/launchpad',
        supportChainIds: [ChainId.BSC],
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
      },
      {
        label: t('Bridge'),
        href: '/bridge',
        supportChainIds: [ChainId.BSC],
          items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
      },
    

      ].filter((item) => filterItemBySupportChainId(item, chainId)),
    },

    // {
    //   label: '', 
    //   href: '',
    //   icon: WhiteMoreIcon,
    //   supportChainIds: [ChainId.BSC],
    //   showItemsOnMobile: false,
    //   isBottomNav: true, 
    //   items: [
    //     {
    //       label: t('Launchpad'),
    //       href: '/launchpad',
    //       icon: BsDatabaseFillUp,
    //       supportChainIds: [ChainId.BSC],
    //       showItemsOnMobile: true,
    //       isBottomNav: true, 

    //       items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    //     },
    //     {
    //       label: t('Bridge'),
    //       href: '/bridge',
    //       icon: GiArchBridge,
    //       supportChainIds: [ChainId.BSC],
    //       showItemsOnMobile: false,
    //       isBottomNav: true, 

    //       items: [].filter((item) => filterItemBySupportChainId(item, chainId)),
    //     },
    //   ].filter((item) => filterItemBySupportChainId(item, chainId)),

    // },
    
  ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
