import multicall, { Call } from 'utils/multicall'
import nftPoolAbi from '../../config/abi/NFTPool.json'
import { getNftPoolConfigs } from 'config/constants/xarxfarmsfam'
import { IPositionInfo, NFTPoolAllowance, UserNFTPoolInfo, UserNftPoolLpData } from './types'
import { getTokenAddress, getTokenInstance } from 'config/constants/token-list'
import { formatEthersToFloat } from 'utils/bigNumber'
import { ERC20_ABI } from 'config/abi/erc20'
import BigNumber from 'bignumber.js'

const defaultUserLpData: UserNftPoolLpData = {
  tokenBalance: '0',
  stakedBalance: '0',
}

export const fetchNftPoolAllowances = async (account: string, chainId: number): Promise<NFTPoolAllowance[]> => {
  const poolConfigs = getNftPoolConfigs(chainId)
  const nftPoolAddresses = poolConfigs.map((f) => f.nftPoolAddress[chainId])

  const allowanceCalls: Call[] = []
  poolConfigs.forEach((conf) => {
    allowanceCalls.push({
      address: conf.lpAddresses[chainId],
      name: 'allowance',
      params: [account, conf.nftPoolAddress[chainId]],
    })
  })

  let allowances = await multicall(
    ['function allowance(address, address) public view returns (uint256)'],
    allowanceCalls,
  )
  allowances = allowances.flat()

  const poolAllowances = nftPoolAddresses.map((poolAddress, idx) => {
    return {
      poolAddress,
      hasLpApproval: allowances[idx].gt(0),
    }
  })

  return poolAllowances
}

export const fetchUserNftPoolBalances = async (account: string, chainId: number): Promise<UserNFTPoolInfo[]> => {
  const poolConfigs = getNftPoolConfigs(chainId)
  const nftPoolAddresses = poolConfigs.map((f) => f.nftPoolAddress[chainId])

  const calls: Call[] = []
  nftPoolAddresses.forEach((address) => {
    calls.push({
      address,
      name: 'balanceOf',
      params: [account],
    })
  })

  const userNftBalances = await multicall(nftPoolAbi, calls)
  const balances = userNftBalances.flat()

  return nftPoolAddresses.map((address, idx) => {
    return {
      poolAddress: address,
      balance: balances[idx].toNumber(),
      userLpData: defaultUserLpData,
    }
  })
}

export const fetchNftPositions = async (account: string, chainId: number): Promise<IPositionInfo[]> => {
  if (!account) return []

  const poolConfigs = getNftPoolConfigs(chainId)
  const nftPoolAddresses = poolConfigs.map((f) => f.nftPoolAddress[chainId])
  const lpTokenAddresses = poolConfigs.map((c) => c.lpAddresses[chainId])

  let pools: any[] = nftPoolAddresses.map((nftPoolAddress) => {
    return {
      nftPoolAddress,
    }
  })

  const calls: Call[] = []
  pools.forEach((pool) => {
    // How many NFT positions user has for pool
    calls.push({
      address: pool.nftPoolAddress,
      name: 'balanceOf',
      params: [account],
    })
  })

  const lpBalanceCalls: Call[] = []
  lpTokenAddresses.forEach((lp) => {
    lpBalanceCalls.push({
      address: lp,
      name: 'balanceOf',
      params: [account],
    })
  })

  const [nftBalances, lpBalances] = await Promise.all([
    multicall(nftPoolAbi, calls),
    multicall(ERC20_ABI, lpBalanceCalls),
  ])

  nftBalances.flat().forEach((bal, idx) => {
    pools[idx].nftBalance = bal.toNumber()
  })

  lpBalances.flat().forEach((bal, idx) => {
    pools[idx].userLpBalance = bal.toString()
  })

  await getAllTokenIds(pools, account)
  await getRewardsInfo(pools, chainId)

  return pools
}

async function getAllTokenIds(pools: IPositionInfo[], account: string) {
  const calldata = []

  pools.forEach((pool) => {
    if (pool.nftBalance > 0) {
      calldata.push({
        ...pool,
        call: {
          address: pool.nftPoolAddress,
          name: 'tokenOfOwnerByIndex',
          params: [account, 0], // just getting first positions token id for now
        },
      })
    }
  })

  const data = await multicall(
    nftPoolAbi,
    calldata.map((data) => data.call),
  )
  const tokenIds: number[] = data.flat().map((id) => id.toNumber())

  calldata.forEach((data, idx) => {
    const pool = pools.find((p) => p.nftPoolAddress == data.nftPoolAddress)
    pool.tokenIds = [tokenIds[idx]]
  })

  pools.forEach((pool) => (pool.tokenIds = pool.tokenIds || []))

  return tokenIds
}

async function getRewardsInfo(pools: IPositionInfo[], chainId: number) {
  const calls = []
  const rewardCalls = []
  const addRewardCalls = []

  const positions = pools.filter((p) => p.tokenIds.length > 0)

  positions.forEach((pool) => {
    pool.tokenIds.forEach((tokenId) => {
      calls.push({
        address: pool.nftPoolAddress,
        name: 'getStakingPosition', // to get the amount("stakedBalance")
        params: [tokenId],
      })

      rewardCalls.push({
        address: pool.nftPoolAddress,
        name: 'pendingRewards',
        params: [tokenId],
      })

      addRewardCalls.push({
        address: pool.nftPoolAddress,
        name: 'pendingAdditionalRewards',
        params: [tokenId],
      })
    })
  })

  const [info, rewards, additionalRewards] = await Promise.all([
    multicall(nftPoolAbi, calls),
    multicall(nftPoolAbi, rewardCalls),
    multicall(nftPoolAbi, addRewardCalls),
  ])

  const mainToken = getTokenInstance(getTokenAddress('ProtocolToken', chainId))
  const xToken = getTokenInstance(getTokenAddress('xProtocolToken', chainId))
  const wethToken = getTokenInstance(getTokenAddress('WETH', chainId))

  positions.forEach((position, idx) => {
    const stakingInfo = info[idx]
    const rewardInfo = rewards[idx]
    const additional = additionalRewards[idx]

    // This is the combined token/xToken amount so needs to be split up
    // TODO: Pool could have different settings than 80/20
    const totalMainAmount = rewardInfo.mainAmount
    const xTokenAmount = totalMainAmount.mul(80).div(100)
    const mainTokenAmount = totalMainAmount.sub(xTokenAmount)

    const baseRewards = [
      {
        token: getTokenAddress('ProtocolToken', chainId),
        pendingReward: 0,
      },
      {
        token: getTokenAddress('xProtocolToken', chainId),
        pendingReward: 0,
      },
      {
        token: getTokenAddress('WETH', chainId),
        pendingReward: 0,
      },
    ]

    baseRewards[0].pendingReward = formatEthersToFloat(mainTokenAmount)
    baseRewards[1].pendingReward = formatEthersToFloat(xTokenAmount)
    baseRewards[2].pendingReward = formatEthersToFloat(rewardInfo.wethAmount)

    position.stakedBalance = new BigNumber(stakingInfo.amount.toString()).toJSON()
    position.pendingRewards = baseRewards
  })
}
