import { ChainId } from '@baseswapfi/sdk-core'
import { getConnection } from 'connection'
import { didUserReject } from 'connection/utils'
import { CHAIN_IDS_TO_ROUTE_NAMES, isSupportedChain } from 'config/constants/chains'
import { useCallback } from 'react'
import { addPopup, PopupType } from 'state/application/reducer'
import { useAppDispatch } from 'state'

import { useSwitchChain } from './useSwitchChain'
import { useRouter } from 'next/router'
import useActiveWeb3React from './useActiveWeb3React'

export default function useSelectChain() {
  const dispatch = useAppDispatch()
  const { connector } = useActiveWeb3React()
  const switchChain = useSwitchChain()
  const router = useRouter()

  return useCallback(
    async (targetChain: ChainId) => {
      if (!connector) return

      const connection = getConnection(connector)

      try {
        await switchChain(connector, targetChain)
        if (isSupportedChain(targetChain)) {
          router.push(router.pathname, {
            pathname: router.pathname,
            query: {
              chain: CHAIN_IDS_TO_ROUTE_NAMES[targetChain],
            },
          })
        }
      } catch (error: any) {
        if (!didUserReject(connection, error) && error.code !== -32002 /* request already pending */) {
          console.error('Failed to switch networks', error)
          dispatch(
            addPopup({
              content: { failedSwitchNetwork: targetChain, type: PopupType.FailedSwitchNetwork },
              key: 'failed-network-switch',
            }),
          )
        }
      }
    },
    [connector, dispatch, router, switchChain],
  )
}
