import { ChainId } from '@baseswapfi/sdk-core'
import AppStaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider'
import StaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider'

import { SupportedInterfaceChain } from './chains'
import { RPC_URLS } from './networks'

const providerFactory = (chainId: SupportedInterfaceChain, i = 0) =>
  new AppStaticJsonRpcProvider(chainId, RPC_URLS[chainId][i])

/**
 * These are the only JsonRpcProviders used directly by the interface.
 */
export const RPC_PROVIDERS: { [key in SupportedInterfaceChain]?: StaticJsonRpcProvider } = {
  [ChainId.ARBITRUM]: providerFactory(ChainId.ARBITRUM),
}

export const DEPRECATED_RPC_PROVIDERS: { [key in SupportedInterfaceChain]?: AppStaticJsonRpcProvider } = {
  [ChainId.ARBITRUM]: providerFactory(ChainId.ARBITRUM),
}
