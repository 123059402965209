import styled from "styled-components"
import {Flex, ModalTitle, ModalHeader as UIKitModalHeader} from '@pancakeswap/uikit'
import Link from "next/link";

export const WalletTitle = styled(Flex)`
  align-items: center; 
  justify-content: center; 

  `;

export const WalletStandardFlex = styled(Flex)`
 border-radius: 24px; 
 margin-bottom: 12px; 
 margin-top: 4px; 
 
 box-shadow: 0px 0px 12px #000; 
`
export const WalletHeaderContainer = styled(WalletStandardFlex)`
  flex-direction: row; 
  border-top: 0px solid #fff; 
  margin-top: 1rem; 
  padding: 8px; 
  justify-content: space-between; 
  align-items: center; 

`
export const TabsContainer = styled(WalletStandardFlex)`
  padding: 12px 4px;
`
  
export const TransactionsFlex = styled(WalletStandardFlex)`
 flex-direction: column; 
 border-radius: 24px; 
 padding: 8px; 
 border: none; 
`

export const InteriorFlex = styled(WalletStandardFlex)`
 flex-direction: column; 
 border-radius: 24px; 
 padding: 12px; 
 border: 0px solid #fff; 
 margin-bottom: 1rem; 
 
 justify-content: flex-start; 

`



