import { useTranslation } from 'contexts/Localization'
import { Box, Flex, Text, useMatchBreakpointsContext } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { getConnection } from 'connection'
import { ConnectionType } from 'connection/types'
import { L1_CHAIN_IDS, L2_CHAIN_IDS, TESTNET_CHAIN_IDS, getChainPriority } from 'config/constants/chains'
import { ChainId } from '@baseswapfi/sdk-core'
import { WalletConnectV2 } from 'connection/WalletConnectV2'
import { getSupportedChainIdsFromWalletConnectSession } from 'utils/getSupportedChainIdsFromWalletConnectSession'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getChainInfo } from 'config/constants/chainInfo'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { ChainLogo } from './Logo/ChainLogo'
import { usePopper } from 'react-popper'
import styled, { useTheme } from 'styled-components'
import { useIsMobile } from 'hooks/useIsMobile'
import { Portal } from './common/Portal'
import { AlertTriangle, ChevronDown, ChevronUp } from 'react-feather'
import { MouseoverTooltip } from './Tooltip'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import TestnetsToggle, { showTestnetsAtom } from './ChainSelector/TestnetsToggle'
import { useAtomValue } from 'jotai'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { useLast } from 'hooks/useLast'
import { useConnectionReady } from 'connection/eagerlyConnect'
import { ConnectionMeta, getPersistedConnectionMeta, setPersistedConnectionMeta } from 'connection/meta'
import useToast from 'hooks/useToast'
import dynamic from 'next/dynamic'

const ChainSelectorRow = dynamic(() => import('./ChainSelector/ChainSelectorRow'), { ssr: false })

export const ChainMenu = styled(Flex)`
  align-items: center; 

  cursor: pointer;
  display: inline-flex;  
  height: 45px;

  margin-left: 0px;
  position: relative;
  &:hover {
    opacity: 0.65;
  }
`

const ChainList = styled.div<{ isOpen: boolean }>`
  background-color: transparent;
  backdrop-filter: blur(24px);
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 18px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  width: 250px;
  visibility: visible;
  z-index: 1001;
  top: 40px !important;
  right: 10px !important;
  position: absolute !important;
  transform: none !important;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
`

const NETWORK_SELECTOR_CHAINS: number[] = [...L1_CHAIN_IDS, ...L2_CHAIN_IDS, ...TESTNET_CHAIN_IDS]

function useWalletSupportedChains(): number[] {
  const { connector } = useWeb3React()
  const connectionType = getConnection(connector).type

  switch (connectionType) {
    case ConnectionType.WALLET_CONNECT_V2:
      return getSupportedChainIdsFromWalletConnectSession((connector as WalletConnectV2).provider?.session)
    default:
      return NETWORK_SELECTOR_CHAINS
  }
}

export const NetworkSelect = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { chainId } = useWeb3React()
  const { toastError } = useToast()

  const switchingChain = useSelector((state: AppState) => state.wallets.switchingChain)
  const ignoreWhileSwitchingChain = useCallback(() => !switchingChain, [switchingChain])
  const connectionReady = useConnectionReady()
  const activeWeb3 = useWeb3React()
  const lastWeb3 = useLast(useWeb3React(), ignoreWhileSwitchingChain)
  const { account, connector } = useMemo(() => (activeWeb3.account ? activeWeb3 : lastWeb3), [activeWeb3, lastWeb3])
  const connection = getConnection(connector)

  // Display a loading state while initializing the connection, based on the last session's persisted connection.
  // The connection will go through three states:
  // - startup:       connection is not ready
  // - initializing:  account is available, but ENS (if preset on the persisted initialMeta) is still loading
  // - initialized:   account and ENS are available
  // Subsequent connections are always considered initialized, and will not display startup/initializing states.
  const initialConnection = useRef(getPersistedConnectionMeta())
  const isConnectionInitializing = Boolean(
    initialConnection.current?.address === account && initialConnection.current?.ENSName,
  )
  const isConnectionInitialized = connectionReady && !isConnectionInitializing
  // Clear the initial connection once initialized so it does not interfere with subsequent connections.
  useEffect(() => {
    if (isConnectionInitialized) {
      initialConnection.current = undefined
    }
  }, [isConnectionInitialized])

  // Persist the connection if it changes, so it can be used to initialize the next session's connection.
  useEffect(() => {
    if (account) {
      const meta: ConnectionMeta = {
        type: connection.type,
        address: account,
        ENSName: undefined,
      }
      setPersistedConnectionMeta(meta)
    }
  }, [account, connection.type])

  const { isMobile } = useMatchBreakpointsContext()
  const { t } = useTranslation()
  const theme = useTheme()

  const showTestnets = useAtomValue(showTestnetsAtom)
  const walletSupportsChain = useWalletSupportedChains() as number[]

  const [supportedChains, unsupportedChains] = useMemo(() => {
    const { supported, unsupported } = NETWORK_SELECTOR_CHAINS.filter((chain: number) => {
      return showTestnets || !TESTNET_CHAIN_IDS.includes(chain)
    })
      .sort((a, b) => getChainPriority(a) - getChainPriority(b))
      .reduce(
        (acc, chain) => {
          if (walletSupportsChain.includes(chain)) {
            acc.supported.push(chain)
          } else {
            acc.unsupported.push(chain)
          }
          return acc
        },
        { supported: [], unsupported: [] } as Record<string, ChainId[]>,
      )
    return [supported, unsupported]
  }, [showTestnets, walletSupportsChain])

  const info = getChainInfo(chainId)
  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<ChainId | undefined>(undefined)

  const onSelectChain = useCallback(
    async (targetChainId: ChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen],
  )

  const isSupported = !!info

  const targetRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(targetRef, () => setIsOpen(false), [tooltipRef])

  const { styles, attributes } = usePopper(targetRef.current, tooltipRef.current, {
    strategy: 'absolute',
    placement: 'bottom-end',
  })

  if (!chainId) {
    return null
  }

  const chevronProps = {
    height: 18,
    width: 18,
    color: theme.colors.text,
  }

  const ChainListDropdown = (
    <>
      <ChainList style={styles.popper} ref={tooltipRef} {...attributes.popper} isOpen={isOpen}>
        {supportedChains.map((selectorChain) => (
          <ChainSelectorRow
            disabled={!walletSupportsChain.includes(selectorChain) || selectorChain === ChainId.SCROLL_SEPOLIA}
            onSelectChain={onSelectChain}
            targetChain={selectorChain}
            key={selectorChain}
            isPending={selectorChain === pendingChainId}
          />
        ))}

        {/* <Flex padding="7px" marginLeft="5px" mt="5px" borderTop="1px solid white" justifyContent="flex-end">
          <Text fontSize="16px" fontWeight={485} marginRight="5px">
            Show Testnets
          </Text>
          <TestnetsToggle />
        </Flex> */}
      </ChainList>
    </>
  )
  return (
    <>
      <Flex  alignItems="flex-end" style={{ position: 'relative' }} justifyContent="center" ref={targetRef}>
        <MouseoverTooltip text={t(`Your wallet's current network is unsupported.`)} disabled={isSupported}>
          <ChainMenu
            onClick={() => {
              setIsOpen((s) => !s)
            }}
          >
            {!isSupported ? (
              <AlertTriangle size={20} color={theme.colors.text} />
            ) : (
              <ChainLogo chainId={chainId} size={24} />
            )}
            {isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
          </ChainMenu>
        </MouseoverTooltip>

        {isOpen && (isMobile ? <Portal>{ChainListDropdown}</Portal> : <>{ChainListDropdown}</>)}
      </Flex>
    </>
  )
}

export const NetworkSwitcher = () => {
  return <NetworkSelect />
}
