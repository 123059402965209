import { useState } from 'react'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  useMatchBreakpointsContext,
  ToggleMe,
} from '@pancakeswap/uikit'
import { parseUnits } from '@ethersproject/units'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { FetchStatus } from 'config/constants/types'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletWrongNetwork from './WalletWrongNetwork'
import Rodal from 'rodal'
import { RodalTitle } from 'components/RodalComponents/styled'
import { FaWallet } from '@react-icons/all-files/fa/FaWallet'
import { FaHistory } from '@react-icons/all-files/fa/FaHistory'
import { WalletHeaderContainer, TabsContainer, WalletTitle } from './styled'

const WalletIcon = ({ isActive }) => (
  <FaWallet style={{ marginRight: '2px', color: isActive ? '#000' : '#0098A1' }} size="14px" />
);

const HistoryIcon = ({ isActive }) => (
  <FaHistory style={{ marginRight: '2px', color: isActive ? '#fff' : '#000' }} size="14px" />
);

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  WRONG_NETWORK,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = parseUnits('2', 'gwei')

const ModalHeader = styled(UIKitModalHeader)`
  background: #000; 
`

const Tabs = styled.div`
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 2rem;
  margin: 1rem; 
`

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.WALLET_INFO, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { isDesktop } = useMatchBreakpointsContext()
  const hasLowBnbBalance = fetchStatus === FetchStatus.Fetched && balance.lte(LOW_BNB_BALANCE)

  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  const TabsComponent: React.FC = () => (
    <TabsContainer>
      <ButtonMenu scale="sm" onItemClick={handleClick} activeIndex={view} fullWidth>
        <ToggleMe style={{ height: 40 }}>
          <WalletIcon isActive={view === 0} />
          {t('WALLET')}
        </ToggleMe>
        <ToggleMe style={{ height: 40 }}>
          <HistoryIcon isActive={view === 0} />
          {t('TRANSACTIONS')}
        </ToggleMe>
      </ButtonMenu>
    </TabsContainer>
  )

  return (
    <Rodal
      visible={true} closeonEsc={true}
      height={450} width={isDesktop ? 420 : 370}
      onClose={onDismiss} duration={450}
      animation={'door'}
      customStyles={{ backgroundColor: '#000', borderRadius: '24px', border: '1px solid #1D2635', boxShadow: '0 0px 96px #0098A1' }}
    >



      {view === WalletView.WALLET_INFO &&
        <RodalTitle >
          {t('MY WALLET')}
        </RodalTitle>

      }
      {view === WalletView.TRANSACTIONS &&
        <RodalTitle>
          {t('MY TRANSACTIONS')}
        </RodalTitle>
      }



      {view !== WalletView.WRONG_NETWORK && <TabsComponent />}

      <ModalBody width="100%">
        {view === WalletView.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} />}
        {view === WalletView.TRANSACTIONS && <WalletTransactions />}
        {view === WalletView.WRONG_NETWORK && <WalletWrongNetwork onDismiss={onDismiss} />}
      </ModalBody>

    </Rodal>
  )
}

export default WalletModal
