var variants = {
    warning: {
        background: "transparent",
        borderColor: "text"
    },
    danger: {
        background: "#ED4B9E19",
        borderColor: "text"
    },
    success: {
        background: "rgba(49, 208, 170, 0.1)",
        borderColor: "success"
    }
};
export default variants;
