interface LoadingInfo {
  isLoading: boolean
}

export interface VestingInfo {
  arxAmount: string
  xArxAmount: string
  endTime: string
  redeemIndex: number
  canFinalize: boolean
}

export interface UserXTokenVestingInfo {
  redemptionCount: number
  vestingList: VestingInfo[]
}

export interface IUserYieldBoosterInfo {
  allocated: string // xToken.usageAllocations(account, yieldBoosterAddress)
  approvalAmount: string
}

export interface UserXTokenInfo extends LoadingInfo {
  protocolTokenBalance: string
  protocolTokenBalanceFormatted: string
  xTokenBalance: string
  xTokenReedeemingBalance: string
  xTokenTotalBalance: string
  yieldBoosterInfo: IUserYieldBoosterInfo
  protocolTokenApprovalAmount: string
}

export interface IXTokenRedeemSettings {
  minRedeemRatio: number
  maxRedeemRatio: number
  minRedeemDuration: number
  maxRedeemDuration: number
}

export interface IYieldBoosterInfo {
  totalAllocation: string
  deallocationFee: number
}

export const defaultUserYieldBoost = {
  allocated: '0',
  approvalAmount: null,
}

export const defaultXTokenUserInfo: UserXTokenInfo = {
  isLoading: true,
  protocolTokenBalance: '0',
  protocolTokenBalanceFormatted: '0',
  xTokenBalance: '0',
  xTokenTotalBalance: '0',
  xTokenReedeemingBalance: '0',
  yieldBoosterInfo: defaultUserYieldBoost,
  protocolTokenApprovalAmount: null,
}

export const defaultRedeemSettings: IXTokenRedeemSettings = {
  minRedeemRatio: 0,
  maxRedeemRatio: 0,
  minRedeemDuration: 0,
  maxRedeemDuration: 0,
}

export const defaultXTokenUserRedeemInfo = {
  redemptionCount: 0,
  vestingList: [],
}

export interface XTokenState {
  userInfo: UserXTokenInfo
  redemptionInfo: UserXTokenVestingInfo
  redeemSettings: IXTokenRedeemSettings
  yieldBoosterInfo: IYieldBoosterInfo
}
