import Toggle from 'components/Toggle'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const showTestnetsAtom = atomWithStorage<boolean>('showTestnets', false)

export default function TestnetsToggle() {
  const [showTestnets, updateShowTestnets] = useAtom(showTestnetsAtom)

  return (
    <Toggle isActive={showTestnets} width="16px" height="16px" toggle={() => updateShowTestnets((value) => !value)} />
  )
}
