import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Button, Text, Link } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'utils/providers'

const StyledLink = styled(Link)`
  width: 100%;
  &:hover {
    text-decoration: initial;
  }
`

interface WalletWrongNetworkProps {
  onDismiss: () => void
}

const WalletWrongNetwork: React.FC<WalletWrongNetworkProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { connector } = useWeb3React()

  const handleSwitchNetwork = async (): Promise<void> => {
    // await setupNetwork(DEFAULT_CHAIN_ID, library)
    onDismiss?.()
  }

  return (
    <>
      {/* <Text mb="24px">{t('You’re connected to the wrong network.')}</Text>
      {connector instanceof InjectedConnector && (
        <Button onClick={handleSwitchNetwork} mb="24px">
          {t('Switch Network')}
        </Button>
      )} */}
    </>
  )
}

export default WalletWrongNetwork
