import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  UserXTokenVestingInfo,
  XTokenState,
  defaultRedeemSettings,
  defaultUserYieldBoost,
  defaultXTokenUserInfo,
} from './types'
import { fetchUserXTokenInfo, fetchUserXTokenRedeemsInfo, getYieldBoosterInfo } from './fetchXTokenInfo'
import { JsonRpcProvider } from '@ethersproject/providers'
import { isSupportedChain } from 'config/constants/chains'

const initialXTokenState: XTokenState = {
  userInfo: defaultXTokenUserInfo,
  redemptionInfo: {
    redemptionCount: 0,
    vestingList: [],
  },
  yieldBoosterInfo: {
    totalAllocation: '0',
    deallocationFee: null,
  },
  redeemSettings: defaultRedeemSettings,
}

export const fetchUserXTokenDataAsync = createAsyncThunk<
  XTokenState,
  { account: string; chainId: number; provider: JsonRpcProvider }
>('xToken/fetchUserXTokenDataAsync', async ({ account, chainId, provider }) => {
  if (!account || !isSupportedChain(chainId)) {
    return initialXTokenState
  }
  const [userInfo, redemptionInfo, 
    // yieldBoosterInfo
  ] = await Promise.all([
    fetchUserXTokenInfo(account, chainId, provider),
    fetchUserXTokenRedeemsInfo(account, chainId, provider),
    // getYieldBoosterInfo(chainId, provider),
  ])
  console.log('userInfo',userInfo)
  return {
    userInfo: userInfo.userInfo,
    redemptionInfo,
    // yieldBoosterInfo,
    redeemSettings: userInfo.redeemSettings,
  }
})

export const fetchUserXTokenRedeemsInfoAsync = createAsyncThunk<
  UserXTokenVestingInfo,
  { account: string; chainId: number; provider: JsonRpcProvider }
>('xToken/fetchUserXTokenRedeemsInfoAsync', async ({ account, chainId, provider }) => {
  // current redeeming info for user
  return fetchUserXTokenRedeemsInfo(account, chainId, provider)
})

export const xTokenSlice = createSlice({
  name: 'xToken',
  initialState: initialXTokenState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserXTokenDataAsync.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })

    builder.addCase(fetchUserXTokenDataAsync.rejected, (state, action) => {
      // console.log(action.error)
      return state
    })

    builder.addCase(fetchUserXTokenRedeemsInfoAsync.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })

    builder.addCase(fetchUserXTokenRedeemsInfoAsync.rejected, (state, action) => {
      // console.log(action.error)
      return state
    })
  },
})

export default xTokenSlice.reducer
