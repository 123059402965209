import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import { connections, deprecatedNetworkConnection, networkConnection } from 'connection'
import { Text } from '@pancakeswap/uikit'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { isSupportedChain } from 'config/constants/chains'
import { useModal, ModalBody, ModalContainer } from '@pancakeswap/uikit'
import Option from './Option'
import { useTranslation } from 'contexts/Localization'
import { ConnectTitle, ConnectHeaderContainer, ConnectTransactionsFlex } from './styled'
import { FaWallet } from '@react-icons/all-files/fa/FaWallet'
import dynamic from 'next/dynamic'
import ConnectionErrorView from './ConnectionErrorView'

// const ConnectionErrorView = dynamic(() => import('./ConnectionErrorView'), { ssr: false })

const OptionGrid = styled.div`
  display: grid;

  grid-gap: 4px;

  overflow: hidden;
`

export const useWalletModal = () => {
  const [onPresentConnectModal, onDismissModal] = useModal(<WalletModal />)
  return { onPresentConnectModal, onDismissModal }
}

function WalletModal() {
  // const [isOpen, setIsOpen] = useState(true)
  const { connector, chainId, account } = useWeb3React()
  const { activationState } = useActivationState()
  const { t } = useTranslation()
  const { onDismissModal } = useWalletModal()
  useEffect(() => {
    if (account) {
      onDismissModal()
    }
  }, [account])

  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (chainId && isSupportedChain(chainId) && connector !== networkConnection.connector) {
      deprecatedNetworkConnection.connector.activate(chainId)
    }
  }, [chainId, connector])

  return (
    <>
      <ModalContainer minWidth="320px" padding="8px">
        <ConnectHeaderContainer>
          <ConnectTitle>
            <FaWallet size="24px" color="#301934" />
            <Text marginLeft="8px" marginRight="8px" fontSize="20px" fontWeight="300">
              {t('CONNECT WALLET')}
            </Text>
          </ConnectTitle>
        </ConnectHeaderContainer>
        <ModalBody minWidth={['320px', null, '340px']}>
          <ConnectTransactionsFlex>
            {activationState.status === ActivationStatus.ERROR ? (
              <ConnectionErrorView />
            ) : (
              <AutoColumn gap="16px">
                <OptionGrid data-testid="option-grid">
                  {connections
                    .filter((connection) => connection.shouldDisplay())
                    .map((connection) => {
                      return <Option key={connection.getName()} connection={connection} />
                    })}
                </OptionGrid>
              </AutoColumn>
            )}
          </ConnectTransactionsFlex>
        </ModalBody>
      </ModalContainer>
    </>
  )
}
