import { serializeTokens } from 'utils/serializeTokens'
import { bscTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens(bscTokens)

export const CAKE_BNB_LP_MAINNET = '0xA6efAE0C9293B4eE340De31022900bA747eaA92D'
// this is arx-usdce with test token as arx  
const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'ARX',
    lpAddresses: {
      97: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
      56: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
      42161: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    v1pid: 0,
    lpSymbol: 'ARX-USDCe LP',
    lpAddresses: {
      97: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
      56: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
      42161: '0xA6efAE0C9293B4eE340De31022900bA747eaA92D',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.usdce,
    classic: true, 
  },
  {
    pid: 2,
    v1pid: 0,
    lpSymbol: 'ETH-USDCe LP',
    lpAddresses: {
      97: '0x4C42fA9Ecc3A17229EDf0fd6A8eec3F11D7E00D3',
      56: '0x4C42fA9Ecc3A17229EDf0fd6A8eec3F11D7E00D3',
      42161: '0x4C42fA9Ecc3A17229EDf0fd6A8eec3F11D7E00D3',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.usdce,
    classic: true, 
  },
  {
    pid: 3,
    v1pid: 0,
    lpSymbol: 'USDT-USDCe LP',
    lpAddresses: {
      97: '0xD082d6E0AF69f74F283b90C3CDa9C35615Bce367',
      56: '0xD082d6E0AF69f74F283b90C3CDa9C35615Bce367',
      42161: '0xD082d6E0AF69f74F283b90C3CDa9C35615Bce367',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdce,
    classic: true, 
  },
  {
  pid: 4,
  v1pid: 0,
  lpSymbol: 'WBTC-USDCe LP',
  lpAddresses: {
    97: '0xA6A6090749B9E3010802C5bFF3845aa6A4AC321B',
    56: '0xA6A6090749B9E3010802C5bFF3845aa6A4AC321B',
    42161: '0xA6A6090749B9E3010802C5bFF3845aa6A4AC321B',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.usdce,
  classic: true, 
},
{
  pid: 5,
  v1pid: 0,
  lpSymbol: 'UP-USDCe LP',
  lpAddresses: {
    97: '0xFeafF2443f2489366a62FDDc0114Ad53B9aa03E9',
    56: '0xFeafF2443f2489366a62FDDc0114Ad53B9aa03E9',
    42161: '0xFeafF2443f2489366a62FDDc0114Ad53B9aa03E9',
  },
  token: serializedTokens.up,
  quoteToken: serializedTokens.usdce,
  classic: true, 
},
{
  pid: 6,
  v1pid: 0,
  lpSymbol: 'WBTC-WETH LP',
  lpAddresses: {
    97: '0x10A12127867d3885Ac64b51cc91a67c907eE51db',
    56: '0x10A12127867d3885Ac64b51cc91a67c907eE51db',
    42161: '0x10A12127867d3885Ac64b51cc91a67c907eE51db',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.wbnb,
  classic: true, 
},
{
  pid: 7,
  v1pid: 0,
  lpSymbol: 'USD+-DAI+ LP',
  lpAddresses: {
    97: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
    56: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
    42161: '0xE8C060d40D7Bc96fCd5b758Bd1437C8653400b0e',
  },
  token: serializedTokens.daip,
  quoteToken: serializedTokens.usdp,
  classic: true, 
},
{
  pid: 8,
  v1pid: 0,
  lpSymbol: 'USD+-USDCe LP',
  lpAddresses: {
    97: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
    56: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
    42161: '0xECe52B1fc32D2B4f22eb45238210b470a64bfDd5',
  },
  token: serializedTokens.usdp,
  quoteToken: serializedTokens.usdce,
  classic: true, 
},
{
  pid: 9,
  v1pid: 0,
  lpSymbol: 'FRAX-USDCe LP',
  lpAddresses: {
    97: '0x9d47610d6Cfc4719D05f4E84344A655fB4FD3f57',
    56: '0x9d47610d6Cfc4719D05f4E84344A655fB4FD3f57',
    42161: '0x9d47610d6Cfc4719D05f4E84344A655fB4FD3f57',
  },
  token: serializedTokens.frax,
  quoteToken: serializedTokens.usdce,
  classic: true, 
},
{
  pid: 10,
  v1pid: 0,
  lpSymbol: 'sfrxETH-ETH LP',
  lpAddresses: {
    97: '0x00cABe722790e27Ca6154E8FC34281384aa7052C',
    56: '0x00cABe722790e27Ca6154E8FC34281384aa7052C',
    42161: '0x00cABe722790e27Ca6154E8FC34281384aa7052C',
  },
  token: serializedTokens.sfrxeth,
  quoteToken: serializedTokens.wbnb,
  classic: true, 
},
{
  pid: 11,
  v1pid: 0,
  lpSymbol: 'ARB-ETH LP',
  lpAddresses: {
    97: '0x39511b74722afE77d532Eb70632B4B59C559019b',
    56: '0x39511b74722afE77d532Eb70632B4B59C559019b',
    42161: '0x39511b74722afE77d532Eb70632B4B59C559019b',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.wbnb,
  classic: true, 
},
{
  pid: 12,
  v1pid: 0,
  lpSymbol: 'ARB-USDCe LP',
  lpAddresses: {
    97: '0xD65Ef54B1ff5D9a452B32Ac0c304d1674F761061',
    56: '0xD65Ef54B1ff5D9a452B32Ac0c304d1674F761061',
    42161: '0xD65Ef54B1ff5D9a452B32Ac0c304d1674F761061',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.usdce,
  classic: true, 
},
// {
//   pid: 13,
//   v1pid: 0,
//   lpSymbol: 'CGLD-USDT LP',
//   lpAddresses: {
//     97: '0x38c74D15055AC406d207017B9b6D549C0D2ecc8c',
//     56: '0x38c74D15055AC406d207017B9b6D549C0D2ecc8c',
//     42161: '0x38c74D15055AC406d207017B9b6D549C0D2ecc8c',
//   },
//   token: serializedTokens.cgld,
//   quoteToken: serializedTokens.usdt,
// },
{
  pid: 14,
  v1pid: 0,
  lpSymbol: 'CGLD-USDT LP',
  lpAddresses: {
    97: '0xe14E08c43ceBd79f95D181543628C8EDe181bD02',
    56: '0xe14E08c43ceBd79f95D181543628C8EDe181bD02',
    42161: '0xe14E08c43ceBd79f95D181543628C8EDe181bD02',
  },
  token: serializedTokens.cgld,
  quoteToken: serializedTokens.usdt,
  classic: true, 
},
{
  pid: 15,
  v1pid: 0,
  lpSymbol: 'ARX-ETH LP',
  lpAddresses: {
    97: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
    56: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
    42161: '0x62FdDfC2D4b35aDec79c6082CA2894eAb01aC0db',
  },
  token: serializedTokens.cake,
  quoteToken: serializedTokens.wbnb,
  classic: true, 
},
{
  pid: 16,
  v1pid: 0,
  lpSymbol: 'DAI-DAI+ LP',
  lpAddresses: {
    97: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
    56: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
    42161: '0xeE5e74Dc56594d070E0827ec270F974A68EBAF22',
  },
  token: serializedTokens.daip,
  quoteToken: serializedTokens.dai,
  classic: true, 
},
{
  pid: 17,
  v1pid: 0,
  lpSymbol: 'USD+-ETH LP',
  lpAddresses: {
    97: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
    56: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
    42161: '0xEa5f97aab76E397E4089137345c38b5c4e7939B3',
  },
  token: serializedTokens.usdp,
  quoteToken: serializedTokens.wbnb,
  classic: true, 
},
{
  pid: 18,
  v1pid: 0,
  lpSymbol: 'WBTC-ETH LP',
  lpAddresses: {
    97: '0x7072abdF4Ade05f48Fa869A62a774bfBA34a10D2',
    56: '0x7072abdF4Ade05f48Fa869A62a774bfBA34a10D2',
    42161: '0x7072abdF4Ade05f48Fa869A62a774bfBA34a10D2',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.wbnb,
  quantum: true, 

},
{
  pid: 19,
  v1pid: 0,
  lpSymbol: 'GMX-USDCe LP',
  lpAddresses: {
    97: '0x75c8e847853c79C31b357C880a8e531fBCb6b52F',
    56: '0x75c8e847853c79C31b357C880a8e531fBCb6b52F',
    42161: '0x75c8e847853c79C31b357C880a8e531fBCb6b52F',
  },
  token: serializedTokens.gmx,
  quoteToken: serializedTokens.usdce,
  classic: true, 
},
{
  pid: 20,
  v1pid: 0,
  lpSymbol: 'GMX-ETH LP',
  lpAddresses: {
    97: '0xF2EBC468bB84A0d220c36695b3c27DAa24beD87c',
    56: '0xF2EBC468bB84A0d220c36695b3c27DAa24beD87c',
    42161: '0xF2EBC468bB84A0d220c36695b3c27DAa24beD87c',
  },
  token: serializedTokens.gmx,
  quoteToken: serializedTokens.wbnb,
  classic: true, 
},
{
  pid: 21,
  v1pid: 0,
  lpSymbol: 'WBTC-ETH LP',
  lpAddresses: {
    97: '0x8a8d968c9534727118aa02965f16f6d398c14b1d',
    56: '0x8a8d968c9534727118aa02965f16f6d398c14b1d',
    42161: '0x8a8d968c9534727118aa02965f16f6d398c14b1d',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.wbnb,
  quantum: true, 
  wide: true, 


},
{
  pid: 22,
  v1pid: 0,
  lpSymbol: 'WETH-USDCe LP',
  lpAddresses: {
    97: '0xcf8ea96ecfdb7800e1eb413bde4af6ce984ab079',
    56: '0xcf8ea96ecfdb7800e1eb413bde4af6ce984ab079',
    42161: '0xcf8ea96ecfdb7800e1eb413bde4af6ce984ab079',
  },
  token: serializedTokens.wbnb,
  quoteToken: serializedTokens.usdce,
  quantum: true, 
  wide: true, 



},
{
  pid: 23,
  v1pid: 0,
  lpSymbol: 'USDT-USDCe LP',
  lpAddresses: {
    97: '0x3fb86a978530d9881627907b428e9ac9c02f9406',
    56: '0x3fb86a978530d9881627907b428e9ac9c02f9406',
    42161: '0x3fb86a978530d9881627907b428e9ac9c02f9406',
  },
  token: serializedTokens.usdt,
  quoteToken: serializedTokens.usdce,
  quantum: true
},
{
  pid: 24,
  v1pid: 0,
  lpSymbol: 'DAI-USDCe LP',
  lpAddresses: {
    97: '0xc4602d1415f32ae23a72ad8cf4371272e25e2f22',
    56: '0xc4602d1415f32ae23a72ad8cf4371272e25e2f22',
    42161: '0xc4602d1415f32ae23a72ad8cf4371272e25e2f22',
  },
  token: serializedTokens.dai,
  quoteToken: serializedTokens.usdce,
  quantum: true
},
{
  pid: 25,
  v1pid: 0,
  lpSymbol: 'WBTC-USDCe LP',
  lpAddresses: {
    97: '0x02ebfb3aac7934cdc6877d5ee8cf5e17e449a9a3',
    56: '0x02ebfb3aac7934cdc6877d5ee8cf5e17e449a9a3',
    42161: '0x02ebfb3aac7934cdc6877d5ee8cf5e17e449a9a3',
  },
  token: serializedTokens.wbtc,
  quoteToken: serializedTokens.usdce,
  quantum: true, 
  wide: true, 


},
{
  pid: 26,
  v1pid: 0,
  lpSymbol: 'ARB-USDCe LP',
  lpAddresses: {
    97: '0xc1e2eda42ae3b3da68b622f03cd04ff3412467f7',
    56: '0xc1e2eda42ae3b3da68b622f03cd04ff3412467f7',
    42161: '0xc1e2eda42ae3b3da68b622f03cd04ff3412467f7',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.usdce,
  quantum: true, 
  wide: true, 

},
{
  pid: 27,
  v1pid: 0,
  lpSymbol: 'ARB-ETH LP',
  lpAddresses: {
    97: '0x3369839e15d31b884dbd4e8e101fda0125fb08e8',
    56: '0x3369839e15d31b884dbd4e8e101fda0125fb08e8',
    42161: '0x3369839e15d31b884dbd4e8e101fda0125fb08e8',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.wbnb,
  quantum: true, 
  wide: true, 

},
{
  pid: 28,
  v1pid: 0,
  lpSymbol: 'RDNT-WETH LP',
  lpAddresses: {
    97: '0xaca4edeee3a98a4311ec4c2a85c92163881f1644',
    56: '0xaca4edeee3a98a4311ec4c2a85c92163881f1644',
    42161: '0xaca4edeee3a98a4311ec4c2a85c92163881f1644',
  },
  token: serializedTokens.rdnt,
  quoteToken: serializedTokens.wbnb,
  quantum: true, 
  wide: true, 
},
{

  pid: 29,
  v1pid: 0,
  lpSymbol: 'FRAX-USDCe LP',
  lpAddresses: {
    97: '0x2d3d9d377c046c218e0f3b5a6bd17c9e673f1d8c',
    56: '0x2d3d9d377c046c218e0f3b5a6bd17c9e673f1d8c',
    42161: '0x2d3d9d377c046c218e0f3b5a6bd17c9e673f1d8c',
  },
  token: serializedTokens.frax,
  quoteToken: serializedTokens.usdce,
  quantum: true, 

},
{
  pid: 30,
  v1pid: 0,
  lpSymbol: 'WETH-USDCe LP',
  lpAddresses: {
    97: '0xa1c443f7ae3cec5a61a163b98461af6dc4a98a90',
    56: '0xa1c443f7ae3cec5a61a163b98461af6dc4a98a90',
    42161: '0xa1c443f7ae3cec5a61a163b98461af6dc4a98a90',
  },
  token: serializedTokens.wbnb,
  quoteToken: serializedTokens.usdce,
  quantum: true, 
  narrow: true, 
},  
{
  pid: 31,
  v1pid: 0,
  lpSymbol: 'GNS-WETH LP',
  lpAddresses: {
    97: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
    56: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
    42161: '0xf2d53b528b30f46d08feb20f1524d04d97b614c4',
  },
  token: serializedTokens.gns,
  quoteToken: serializedTokens.wbnb,
  quantum: true
},
{
  pid: 34,
  v1pid: 0,
  lpSymbol: 'FRAX-USD+ LP',
  lpAddresses: {
    97: '0xb0Fb1787238879171Edc30b9730968600D55762A',
    56: '0xb0Fb1787238879171Edc30b9730968600D55762A',
    42161: '0xb0Fb1787238879171Edc30b9730968600D55762A',
  },
  token: serializedTokens.frax,
  quoteToken: serializedTokens.usdp,
  quantum: false,
  classic: true, 
},
{
  pid: 35,
  v1pid: 0,
  lpSymbol: 'FRAX-DAI+ LP',
  lpAddresses: {
    97: '0x306132b6147751B85E608B4C1EC452E111531eA2',
    56: '0x306132b6147751B85E608B4C1EC452E111531eA2',
    42161: '0x306132b6147751B85E608B4C1EC452E111531eA2',
  },
  token: serializedTokens.frax,
  quoteToken: serializedTokens.daip,
  quantum: false,
  classic: true, 
},
{
  pid: 36,
  v1pid: 0,
  lpSymbol: 'USDC-USDCe LP',
  lpAddresses: {
    97: '0x81e8be7795ed3d8619f037b8db8c80292332aa72',
    56: '0x81e8be7795ed3d8619f037b8db8c80292332aa72',
    42161: '0x81e8be7795ed3d8619f037b8db8c80292332aa72',
  },
  token: serializedTokens.usdc,
  quoteToken: serializedTokens.usdce,
  quantum: true,
},
 {
  pid: 37,
  v1pid: 0,
  lpSymbol: 'USDC-WETH LP',
  lpAddresses: {
    97: '0x1bde4a91d0ff7a353b511186768f4cc070874556',
    56: '0x1bde4a91d0ff7a353b511186768f4cc070874556',
    42161: '0x1bde4a91d0ff7a353b511186768f4cc070874556',
  },
  token: serializedTokens.usdc,
  quoteToken: serializedTokens.wbnb,
  quantum: true,
  narrow: true, 
},
{
  pid: 41,
  v1pid: 0,
  lpSymbol: 'FRAX-WETH LP',
  lpAddresses: {
    97: '0xde553150ef951800d2c85b06ee3012113d7a262f',
    56: '0xde553150ef951800d2c85b06ee3012113d7a262f',
    42161: '0xde553150ef951800d2c85b06ee3012113d7a262f',
  },
  token: serializedTokens.frax,
  quoteToken: serializedTokens.wbnb,
  quantum: true,
  narrow: true, 
},
{
  pid: 42,
  v1pid: 0,
  lpSymbol: 'frxETH-WETH LP',
  lpAddresses: {
    97: '0xd3b90a1780b58c59a4333a879c7df488876565f6',
    56: '0xd3b90a1780b58c59a4333a879c7df488876565f6',
    42161: '0xd3b90a1780b58c59a4333a879c7df488876565f6',
  },
  token: serializedTokens.frxeth,
  quoteToken: serializedTokens.wbnb,
  quantum: true,
  narrow: true, 
},
{
  pid: 43,
  v1pid: 0,
  lpSymbol: 'frxETH-FRAX LP',
  lpAddresses: {
    97: '0x1379fa99279129476a54108259da487294d53b97',
    56: '0x1379fa99279129476a54108259da487294d53b97',
    42161: '0x1379fa99279129476a54108259da487294d53b97',
  },
  token: serializedTokens.frxeth,
  quoteToken: serializedTokens.frax,
  quantum: true,
  narrow: true, 
},
{
  pid: 44,
  v1pid: 0,
  lpSymbol: 'ARB-USDC LP',
  lpAddresses: {
    97: '0xdbc35420ada6f048225d5f56e4744f467ae98d99',
    56: '0xdbc35420ada6f048225d5f56e4744f467ae98d99',
    42161: '0xdbc35420ada6f048225d5f56e4744f467ae98d99',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.usdce,
  quantum: true,
  narrow: true, 
},
{
  pid: 45,
  v1pid: 0,
  lpSymbol: 'ARB-USDCe LP',
  lpAddresses: {
    97: '0x4b5cdc175ea9d2526bffd156aea3d22ff6145798',
    56: '0x4b5cdc175ea9d2526bffd156aea3d22ff6145798',
    42161: '0x4b5cdc175ea9d2526bffd156aea3d22ff6145798',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.usdce,
  quantum: true,
  narrow: true, 
},
{
  pid: 46,
  v1pid: 0,
  lpSymbol: 'JEUR-USDCe LP',
  lpAddresses: {
    97: '0x3f8e688806829f9ba85abbd0c80399230cd80427',
    56: '0x3f8e688806829f9ba85abbd0c80399230cd80427',
    42161: '0x3f8e688806829f9ba85abbd0c80399230cd80427',
  },
  token: serializedTokens.jeur,
  quoteToken: serializedTokens.usdce,
  quantum: true,
  narrow: true, 
},
{
  pid: 47,
  v1pid: 0,
  lpSymbol: 'JRT-USDCe LP',
  lpAddresses: {
    97: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
    56: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
    42161: '0x47ff6facEE1B0E136C88Ed1A7393691Ed794BD59',
  },
  token: serializedTokens.jrt,
  quoteToken: serializedTokens.usdce,
  quantum: true,
  narrow: true, 
},
{
  pid: 49,
  v1pid: 0,
  lpSymbol: 'UND-DAI LP',
  lpAddresses: {
    97: '0x05281948a2897d1a4a2e3a22889dc44aeb518669',
    56: '0x05281948a2897d1a4a2e3a22889dc44aeb518669',
    42161: '0x05281948a2897d1a4a2e3a22889dc44aeb518669',
  },
  token: serializedTokens.und,
  quoteToken: serializedTokens.dai,
  quantum: true,
  narrow: true, 
},
{
  pid: 50,
  v1pid: 0,
  lpSymbol: 'WETH-USDC LP',
  lpAddresses: {
    97: '0xc4e56BFe61A5259CBd5Ad1B9C98c75A97C337B7B',
    56: '0xc4e56BFe61A5259CBd5Ad1B9C98c75A97C337B7B',
    42161: '0xc4e56BFe61A5259CBd5Ad1B9C98c75A97C337B7B',
  },
  token: serializedTokens.wbnb,
  quoteToken: serializedTokens.usdc,
  quantum: false,
  classic: true
},
{
  pid: 51,
  v1pid: 0,
  lpSymbol: 'USDC-USDCe LP',
  lpAddresses: {
    97: '0x9c1CCb589C9B9960D03B008da31EA2bb0d1338D9',
    56: '0x9c1CCb589C9B9960D03B008da31EA2bb0d1338D9',
    42161: '0x9c1CCb589C9B9960D03B008da31EA2bb0d1338D9',
  },
  token: serializedTokens.usdc,
  quoteToken: serializedTokens.usdce,
  quantum: false,
  classic: true
},
{
  pid: 52,
  v1pid: 0,
  lpSymbol: 'ARB-USDC LP',
  lpAddresses: {
    97: '0x359491ad6caA061E29ee7EF3d47019bAC972B503',
    56: '0x359491ad6caA061E29ee7EF3d47019bAC972B503',
    42161: '0x359491ad6caA061E29ee7EF3d47019bAC972B503',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.usdc,
  quantum: false,
  classic: true
},
{
  pid: 53,
  v1pid: 0,
  lpSymbol: 'ARB-WETH LP',
  lpAddresses: {
    97: '0x39511b74722afE77d532Eb70632B4B59C559019b',
    56: '0x39511b74722afE77d532Eb70632B4B59C559019b',
    42161: '0x39511b74722afE77d532Eb70632B4B59C559019b',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.wbnb,
  quantum: false,
  classic: true
},

{
  pid: 99,
  v1pid: 0,
  lpSymbol: 'ARX-ARB LP',
  lpAddresses: {
    97: '0x4F7B0Fb8875eca773e1BEB17B2D4F54b8650C1Dc',
    56: '0x4F7B0Fb8875eca773e1BEB17B2D4F54b8650C1Dc',
    42161: '0x4F7B0Fb8875eca773e1BEB17B2D4F54b8650C1Dc',
  },
  token: serializedTokens.arb,
  quoteToken: serializedTokens.cake,
},
//deadpool for tracking 


]
//0x3f8e688806829f9ba85abbd0c80399230cd80427 - jEUR USDC


export default farms
