import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { serializeTokens } from 'utils/serializeTokens'
import { bscTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens(bscTokens)

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto ARX</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address.toLowerCase()}.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Earn ARX</Trans>,
    description: <Trans>Stake and Earn</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address.toLowerCase()}.png`,
      secondarySrc: `/images/tokens/${bscTokens.cake.address.toLowerCase()}.png`,

      // secondarySrc: '/images/tokens/${bscTokens.cake.address.toLowerCase()}.png',
    },
  },
  [VaultKey.CakeFlexibleSideVault]: {
    name: <Trans>Flexible ARX</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address.toLowerCase()}.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  // [VaultKey.IfoPool]: {
  //   name: 'IFO ARX',
  //   description: <Trans>Stake ARX to participate in IFOs</Trans>,
  //   autoCompoundFrequency: 1,
  //   gasLimit: 500000,
  //   tokenImage: {
  //     primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
  //     secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
  //   },
  // },
} as const

// ARXPool: 0x1c8963cacD257E25102eE451952d8f8a80b26c41
// ArbiFlexPool: 0xfE3ea01ef2022e4c889095B3b33546049C0B40Db
// Earn WBTC: 0xE8b58aB51a5488535a487b5369b3cC9fBEc04b68
// Earn WETH: 0x3951E323361d07B116D18701c8ed14BF31CC5d66
// Earn USDCe: 0x37745BABFc8C110a44ad60645F86D32f49fC9Aa9

export const livePools: SerializedPoolConfig[] = [
  //ARBIDEX STAKERS
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0xee1D57aCE6350D70E8161632769d29D34B2FbfC8',
      56: '0xee1D57aCE6350D70E8161632769d29D34B2FbfC8',
      42161: '0xee1D57aCE6350D70E8161632769d29D34B2FbfC8',
      // 42161: '0x489732e4D028e500C327F1424931d428Ba695dF6',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
    autoCompounder: {
      97: '0x2742f8812A1155C486a7d46d236a36d5F527879f',
      56: '0x2742f8812A1155C486a7d46d236a36d5F527879f',
      42161: '0x2742f8812A1155C486a7d46d236a36d5F527879f',
    },
    autoCompounderEnabled: false,
  },
  {
    sousId: 10,
    stakingToken: serializedTokens.xarx,
    earningToken: serializedTokens.usdp,
    contractAddress: {
      97: '0xE9cfb3Aec24A18d13A045B143D0F369FE8d0F4Cc',
      56: '0xE9cfb3Aec24A18d13A045B143D0F369FE8d0F4Cc',
      42161: '0xE9cfb3Aec24A18d13A045B143D0F369FE8d0F4Cc',
    },
    poolCategory: PoolCategory.CORE,
    // 0 
    tokenPerBlock: '0.0000 ',
    //$0
    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      56: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      42161: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
    },
    autoCompounderEnabled: false,
  },
  {
    sousId: 12,
    stakingToken: serializedTokens.xarx,
    earningToken: serializedTokens.daip,
    contractAddress: {
      97: '0x26C7cE64c756c27A87f57FE610bab7e33863CDEe',
      56: '0x26C7cE64c756c27A87f57FE610bab7e33863CDEe',
      42161: '0x26C7cE64c756c27A87f57FE610bab7e33863CDEe',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00000',
    // tokenPerBlock: '0.0, $0 

    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      56: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      42161: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
    },
    autoCompounderEnabled: false,
  },

  {
    sousId: 1,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.wbtc,
    contractAddress: {
      97: '0x907E5d334F27a769EF779358089fE5fdAA6cf2Bb',
      56: '0x907E5d334F27a769EF779358089fE5fdAA6cf2Bb',
      42161: '0x907E5d334F27a769EF779358089fE5fdAA6cf2Bb',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00000000',
    // tokenPerBlock: '0.00000010', was about $230 now 70

    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0x494203AA41AC067bEb684912ad546d113925a13B',
      56: '0x494203AA41AC067bEb684912ad546d113925a13B',
      42161: '0x494203AA41AC067bEb684912ad546d113925a13B',
    },
    autoCompounderEnabled: true,
  },
  {
    sousId: 69,
    stakingToken: serializedTokens.xarx,
    earningToken: serializedTokens.usdc,
    contractAddress: {
      97: '',
      56: '',
      42161: '0x4cB69d9D05f7a4b30D99FCe79e3841173C8D2d2E',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.000330185 ',
    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      56: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      42161: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
    },
    autoCompounderEnabled: false,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.weth,
    contractAddress: {
      97: '0x75Bca51be93E97FF7D3198506f368b472730265a',
      56: '0x75Bca51be93E97FF7D3198506f368b472730265a',
      42161: '0x75Bca51be93E97FF7D3198506f368b472730265a',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000001',
    // tokenPerBlock: '0.0000014496', $35

    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xd0D55B4C435823c1c2F4ed83877dC5B6e6e6200A',
      56: '0xd0D55B4C435823c1c2F4ed83877dC5B6e6e6200A',
      42161: '0xd0D55B4C435823c1c2F4ed83877dC5B6e6e6200A',
    },
    autoCompounderEnabled: true,
  },
  {
    sousId: 7,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.usdce,
    contractAddress: {
      97: '0x2a18d189505e9b8ccb7d69c9163942389108ab25',
      56: '0x2a18d189505e9b8ccb7d69c9163942389108ab25',
      42161: '0x2a18d189505e9b8ccb7d69c9163942389108ab25',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000',
    // tokenPerBlock: '0.0011', was $95 now $43

    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xc2989CbDB414d2fF3bDE98C95836f52dDcAc13e7',
      56: '0xc2989CbDB414d2fF3bDE98C95836f52dDcAc13e7',
      42161: '0xc2989CbDB414d2fF3bDE98C95836f52dDcAc13e7',
    },
    autoCompounderEnabled: true,
  },

  {
    sousId: 9,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.fxs,
    contractAddress: {
      97: '0x99D3Be7600D4daAc5De02690A97e121E4E417330',
      56: '0x99D3Be7600D4daAc5De02690A97e121E4E417330',
      42161: '0x99D3Be7600D4daAc5De02690A97e121E4E417330',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00000',
    // tokenPerBlock: '0.000216', was $112 now $36

    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xc2989CbDB414d2fF3bDE98C95836f52dDcAc13e7',
      56: '0xc2989CbDB414d2fF3bDE98C95836f52dDcAc13e7',
      42161: '0xc2989CbDB414d2fF3bDE98C95836f52dDcAc13e7',
    },
    autoCompounderEnabled: false,
  },
  {
    sousId: 4,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.arb,
    contractAddress: {
      97: '0xf4752a5f352459948620e7C377b10ddcC92015c8',
      56: '0xf4752a5f352459948620e7C377b10ddcC92015c8',
      42161: '0xf4752a5f352459948620e7C377b10ddcC92015c8',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000',
    // tokenPerBlock: '0.001548', was $131 now $41 

    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xDA594c20925712d60d0e7f8D70c9b8586bcd353f',
      56: '0xDA594c20925712d60d0e7f8D70c9b8586bcd353f',
      42161: '0xDA594c20925712d60d0e7f8D70c9b8586bcd353f',
    },
    autoCompounderEnabled: true,
  },
  {
    sousId: 6,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cgld,
    contractAddress: {
      97: '0xe633C4321B066c1348B8D1694461Bc58161a8125',
      56: '0xe633C4321B066c1348B8D1694461Bc58161a8125',
      42161: '0xe633C4321B066c1348B8D1694461Bc58161a8125',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000',
    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0x86B89ce3190724E370313a63D528F444627575B8',
      56: '0x86B89ce3190724E370313a63D528F444627575B8',
      42161: '0x86B89ce3190724E370313a63D528F444627575B8',
    },
    autoCompounderEnabled: true,
  },
  {
    sousId: 8,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gns,
    contractAddress: {
      97: '0x87687Ffb83ab59379C3e2e853cb9Cb3DcC40dA2D',
      56: '0x87687Ffb83ab59379C3e2e853cb9Cb3DcC40dA2D',
      42161: '0x87687Ffb83ab59379C3e2e853cb9Cb3DcC40dA2D',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000',
    //already $22 
    isFinished: false,
    version: 3,
    autoCompounder: {
      97: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      56: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
      42161: '0xc0a1B0ae9EE6c065E6cB2C302784697513D9A3D1',
    },
    autoCompounderEnabled: true,
  },
  // {
  //   sousId: 3,
  //   stakingToken: serializedTokens.cake,
  //   earningToken: serializedTokens.usdce,
  //   contractAddress: {
  //     97: '0x466f4380327cD948572AE0C98f2E04930ce05767',
  //     56: '0x466f4380327cD948572AE0C98f2E04930ce05767',
  //     42161: '0x466f4380327cD948572AE0C98f2E04930ce05767',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.000',
  //   isFinished: true,
  //   version: 3,
  //   autoCompounder: {
  //     97: '0xFb5882514F843210aDA57Fdf91C45543048F8885',
  //     56: '0xFb5882514F843210aDA57Fdf91C45543048F8885',
  //     42161: '0xFb5882514F843210aDA57Fdf91C45543048F8885',
  //   },
  //   autoCompounderEnabled: true,
  //   discontinued: true,
  // },
]

// {
//   sousId: 5,
//   stakingToken: serializedTokens.cake,
//   earningToken: serializedTokens.cgld,
//   contractAddress: {
//     97: '0x49b190c6C7Bf1bbb3765CbE4e0823b44e8a81f9F',
//     56: '0x49b190c6C7Bf1bbb3765CbE4e0823b44e8a81f9F',
//     42161: '0x49b190c6C7Bf1bbb3765CbE4e0823b44e8a81f9F',
//   },
//   poolCategory: PoolCategory.CORE,
//   tokenPerBlock: '0.00',
//   isFinished: true,
//   version: 3,
//   autoCompounder: {
//     97: '0x86B89ce3190724E370313a63D528F444627575B8',
//     56: '0x86B89ce3190724E370313a63D528F444627575B8',
//     42161: '0x86B89ce3190724E370313a63D528F444627575B8',
//   },
//   autoCompounderEnabled: false,
// },

// autocompounder strategies
// btc:
// 0x5BFB6A5c42cc12fde7F846d133bdE8E58DB8aa24

// weth:
// 0xC34d2315103fE37bb65c43b799f9a3d46C753F14

// usdce:
// 0x623b6b8CfE91a3D903071196565515F57b09c71f

// arb:
// 0xe19f481DBfb9050B7c95C33A21f99F60c5eE19Ee

// cgold:
// 0x398C69C44106e41E1f2293F15CE9ecd3f371179f

// 0xe633C4321B066c1348B8D1694461Bc58161a8125
// known finished pools
const finishedPools = [
  // {
  //   sousId: 285,
  //   stakingToken: serializedTokens.cake,
  //   earningToken: serializedTokens.sdao,
  //   contractAddress: {
  //     56: '0x168eF2e470bfeAEB32BE52FB218A41483904851c',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.405',
  //   version: 3,
  // },
].map((p) => ({ ...p, isFinished: true }))

export default [...livePools, ...finishedPools]
